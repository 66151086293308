import styled from 'styled-components';

export const ImageWrapper = styled.div`
  width: 69px;
  height: 100%;

  display: flex;
  justify-content: flex-end;

  > img {
    max-width: 100%;
    margin-top: auto;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2px;
  overflow: hidden;

  padding-top: 8px;
  padding-left: 70px;
  padding-right: 60px;
  padding-bottom: 8px;

  height: 72px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;

  width: 100%;
`;

export const Title = styled.div`
  font-family: 'sukhumvitset';
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
  color: var(--grey900);
`;

export const Score = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 155px;
  width: 155px;
  height: 100%;

  font-family: 'Avenir LT Std';
  font-size: 48px;
  font-weight: 900;
  line-height: 1;
  color: var(--ciBlue);

  &.total{
    color: var(--ciOrange)
  }
`;
