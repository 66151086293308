import { keyBy } from 'lodash';

const partiesList = {
  "data": [
      {
          "refCode": "1",
          "number": "1",
          "color": "#142545",
          "name": "ใหม่",
          "translations": {
              "th": {
                  "name": "ใหม่"
              },
              "en": {
                  "name": "New"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/1.png"
          },
          "presenters": []
      },
      {
          "refCode": "2",
          "number": "2",
          "color": "#f05e00",
          "name": "ประชาธิปไตยใหม่",
          "translations": {
              "th": {
                  "name": "ประชาธิปไตยใหม่"
              },
              "en": {
                  "name": "New Democract"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/2.png"
          },
          "presenters": []
      },
      {
          "refCode": "3",
          "number": "3",
          "color": "#0b4ca2",
          "name": "เป็นธรรม",
          "translations": {
              "th": {
                  "name": "เป็นธรรม"
              },
              "en": {
                  "name": "Fair"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/3.png"
          },
          "presenters": []
      },
      {
          "refCode": "4",
          "number": "4",
          "color": "#008c44",
          "name": "ท้องที่ไทย",
          "translations": {
              "th": {
                  "name": "ท้องที่ไทย"
              },
              "en": {
                  "name": "The Party Of Thai Countries"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/4.png"
          },
          "presenters": []
      },
      {
          "refCode": "5",
          "number": "5",
          "color": "#bf1a1b",
          "name": "พลังสังคมใหม่",
          "translations": {
              "th": {
                  "name": "พลังสังคมใหม่"
              },
              "en": {
                  "name": "Plung Sungkom Mai"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/5.png"
          },
          "presenters": []
      },
      {
          "refCode": "6",
          "number": "6",
          "color": "#ff650e",
          "name": "ครูไทยเพื่อประชาชน",
          "translations": {
              "th": {
                  "name": "ครูไทยเพื่อประชาชน"
              },
              "en": {
                  "name": "Thai Teachers For People"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/6.png"
          },
          "presenters": []
      },
      {
          "refCode": "7",
          "number": "7",
          "color": "#2C3487",
          "name": "ภูมิใจไทย",
          "translations": {
              "th": {
                  "name": "ภูมิใจไทย"
              },
              "en": {
                  "name": "Bhumjaithai"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/7.png"
          },
          "presenters": [
              {
                  "translations": {
                      "th": {
                          "title": "นาย",
                          "firstName": "อนุทิน",
                          "middleName": "",
                          "lastName": "ชาญวีรกุล",
                          "fullName": "อนุทิน ชาญวีรกุล"
                      },
                      "en": {
                          "title": "นาย",
                          "firstName": "อนุทิน",
                          "middleName": "",
                          "lastName": "ชาญวีรกุล",
                          "fullName": "อนุทิน ชาญวีรกุล"
                      }
                  },
                  "images": {
                      "squareCenterUrl": "https://election66-asset.thaipbs.or.th/presenters-formation/squareCenter/7.png",
                      "centerUrl": "https://election66-asset.thaipbs.or.th/presenters-formation/center/7.png",
                      "motionHEVCUrl": "https://election66-asset.thaipbs.or.th/presenters/motionHEVC/7.mp4",
                      "motionWEBMUrl": "https://election66-asset.thaipbs.or.th/presenters/motionWEBM/7.webm"
                  },
                  "imagesCard": {
                      "centerUrl": "https://election66-asset.thaipbs.or.th/presenters/center/7.png",
                      "motionHEVCUrl": "https://election66-asset.thaipbs.or.th/presenters/motionHEVC/7.mp4",
                      "motionWEBMUrl": "https://election66-asset.thaipbs.or.th/presenters/motionWEBM/7.webm"
                  }
              }
          ]
      },
      {
          "refCode": "8",
          "number": "8",
          "color": "#5000c0",
          "name": "แรงงานสร้างชาติ",
          "translations": {
              "th": {
                  "name": "แรงงานสร้างชาติ"
              },
              "en": {
                  "name": "Nation Building Labour"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/8.png"
          },
          "presenters": []
      },
      {
          "refCode": "9",
          "number": "9",
          "color": "#312a7a",
          "name": "พลัง",
          "translations": {
              "th": {
                  "name": "พลัง"
              },
              "en": {
                  "name": "Power"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/9.png"
          },
          "presenters": []
      },
      {
          "refCode": "10",
          "number": "10",
          "color": "#A6181E",
          "name": "อนาคตไทย",
          "translations": {
              "th": {
                  "name": "อนาคตไทย"
              },
              "en": {
                  "name": "Thailand's Future"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/10.png"
          },
          "presenters": []
      },
      {
          "refCode": "11",
          "number": "11",
          "color": "#A56F05",
          "name": "ประชาชาติ",
          "translations": {
              "th": {
                  "name": "ประชาชาติ"
              },
              "en": {
                  "name": "Prachachat"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/11.png"
          },
          "presenters": [
              {
                  "translations": {
                      "th": {
                          "title": "",
                          "firstName": "",
                          "middleName": "",
                          "lastName": "",
                          "fullName": "วันมูหะมัดนอร์ มะทา"
                      },
                      "en": {
                          "title": "",
                          "firstName": "",
                          "middleName": "",
                          "lastName": "",
                          "fullName": "วันมูหะมัดนอร์ มะทา"
                      }
                  },
                  "images": {
                      "squareCenterUrl": "https://election66-asset.thaipbs.or.th/presenters-formation/squareCenter/11.png",
                      "centerUrl": "https://election66-asset.thaipbs.or.th/presenters-formation/center/11.png",
                      "motionHEVCUrl": "https://election66-asset.thaipbs.or.th/presenters/motionHEVC/11.mp4",
                      "motionWEBMUrl": "https://election66-asset.thaipbs.or.th/presenters/motionWEBM/11.webm"
                  },
                  "imagesCard": {
                      "centerUrl": "https://election66-asset.thaipbs.or.th/presenters/center/11.png",
                      "motionHEVCUrl": "https://election66-asset.thaipbs.or.th/presenters/motionHEVC/11.mp4",
                      "motionWEBMUrl": "https://election66-asset.thaipbs.or.th/presenters/motionWEBM/11.webm"
                  }
              }
          ]
      },
      {
          "refCode": "12",
          "number": "12",
          "color": "#0000ff",
          "name": "ไทยรวมไทย",
          "translations": {
              "th": {
                  "name": "ไทยรวมไทย"
              },
              "en": {
                  "name": "Thairuamthai"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/12.png"
          },
          "presenters": []
      },
      {
          "refCode": "13",
          "number": "13",
          "color": "#e1a533",
          "name": "ไทยชนะ",
          "translations": {
              "th": {
                  "name": "ไทยชนะ"
              },
              "en": {
                  "name": "Thaichana"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/13.png"
          },
          "presenters": []
      },
      {
          "refCode": "14",
          "number": "14",
          "color": "#00379a",
          "name": "ชาติพัฒนากล้า",
          "translations": {
              "th": {
                  "name": "ชาติพัฒนากล้า"
              },
              "en": {
                  "name": "Chartpattanakla"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/14.png"
          },
          "presenters": [
              {
                  "translations": {
                      "th": {
                          "title": "นาย",
                          "firstName": "กรณ์",
                          "middleName": "",
                          "lastName": "จาติกวณิช",
                          "fullName": "กรณ์ จาติกวณิช"
                      },
                      "en": {
                          "title": "นาย",
                          "firstName": "กรณ์",
                          "middleName": "",
                          "lastName": "จาติกวณิช",
                          "fullName": "กรณ์ จาติกวณิช"
                      }
                  },
                  "images": {
                      "squareCenterUrl": "https://election66-asset.thaipbs.or.th/presenters-formation/squareCenter/14.png",
                      "centerUrl": "https://election66-asset.thaipbs.or.th/presenters-formation/center/14.png",
                      "motionHEVCUrl": "https://election66-asset.thaipbs.or.th/presenters/motionHEVC/14.mp4",
                      "motionWEBMUrl": "https://election66-asset.thaipbs.or.th/presenters/motionWEBM/14.webm"
                  },
                  "imagesCard": {
                      "centerUrl": "https://election66-asset.thaipbs.or.th/presenters/center/14.png",
                      "motionHEVCUrl": "https://election66-asset.thaipbs.or.th/presenters/motionHEVC/14.mp4",
                      "motionWEBMUrl": "https://election66-asset.thaipbs.or.th/presenters/motionWEBM/14.webm"
                  }
              }
          ]
      },
      {
          "refCode": "15",
          "number": "15",
          "color": "#0F9B1F",
          "name": "กรีน",
          "translations": {
              "th": {
                  "name": "กรีน"
              },
              "en": {
                  "name": "Green"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/15.png"
          },
          "presenters": []
      },
      {
          "refCode": "16",
          "number": "16",
          "color": "#009e42",
          "name": "พลังสยาม",
          "translations": {
              "th": {
                  "name": "พลังสยาม"
              },
              "en": {
                  "name": "Siamese Power"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/16.png"
          },
          "presenters": []
      },
      {
          "refCode": "17",
          "number": "17",
          "color": "#5cc6ec",
          "name": "เสมอภาค",
          "translations": {
              "th": {
                  "name": "เสมอภาค"
              },
              "en": {
                  "name": "Equality"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/17.png"
          },
          "presenters": []
      },
      {
          "refCode": "18",
          "number": "18",
          "color": "#E01883",
          "name": "ชาติไทยพัฒนา",
          "translations": {
              "th": {
                  "name": "ชาติไทยพัฒนา"
              },
              "en": {
                  "name": "Chartthaipattana"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/18.png"
          },
          "presenters": [
              {
                  "translations": {
                      "th": {
                          "title": "นาย",
                          "firstName": "วราวุธ",
                          "middleName": "",
                          "lastName": "ศิลปอาชา",
                          "fullName": "วราวุธ ศิลปอาชา"
                      },
                      "en": {
                          "title": "นาย",
                          "firstName": "วราวุธ",
                          "middleName": "",
                          "lastName": "ศิลปอาชา",
                          "fullName": "วราวุธ ศิลปอาชา"
                      }
                  },
                  "images": {
                      "squareCenterUrl": "https://election66-asset.thaipbs.or.th/presenters-formation/squareCenter/18.png",
                      "centerUrl": "https://election66-asset.thaipbs.or.th/presenters-formation/center/18.png",
                      "motionHEVCUrl": "https://election66-asset.thaipbs.or.th/presenters/motionHEVC/18.mp4",
                      "motionWEBMUrl": "https://election66-asset.thaipbs.or.th/presenters/motionWEBM/18.webm"
                  },
                  "imagesCard": {
                      "centerUrl": "https://election66-asset.thaipbs.or.th/presenters/center/18.png",
                      "motionHEVCUrl": "https://election66-asset.thaipbs.or.th/presenters/motionHEVC/18.mp4",
                      "motionWEBMUrl": "https://election66-asset.thaipbs.or.th/presenters/motionWEBM/18.webm"
                  }
              }
          ]
      },
      {
          "refCode": "19",
          "number": "19",
          "color": "#ed0e90",
          "name": "ภาคีเครือข่ายไทย",
          "translations": {
              "th": {
                  "name": "ภาคีเครือข่ายไทย"
              },
              "en": {
                  "name": "Thai Network"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/19.png"
          },
          "presenters": []
      },
      {
          "refCode": "20",
          "number": "20",
          "color": "#e8172d",
          "name": "เปลี่ยน",
          "translations": {
              "th": {
                  "name": "เปลี่ยน"
              },
              "en": {
                  "name": "Change"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/20.png"
          },
          "presenters": []
      },
      {
          "refCode": "21",
          "number": "21",
          "color": "#EBC500",
          "name": "ไทยภักดี",
          "translations": {
              "th": {
                  "name": "ไทยภักดี"
              },
              "en": {
                  "name": "Thaipakdee"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/21.png"
          },
          "presenters": []
      },
      {
          "refCode": "22",
          "number": "22",
          "color": "#000958",
          "name": "รวมไทยสร้างชาติ",
          "translations": {
              "th": {
                  "name": "รวมไทยสร้างชาติ"
              },
              "en": {
                  "name": "United Thai Nation"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/22.png"
          },
          "presenters": [
              {
                  "translations": {
                      "th": {
                          "title": "พลเอก",
                          "firstName": "ประยุทธ์",
                          "middleName": "",
                          "lastName": "จันทร์โอชา",
                          "fullName": "พลเอกประยุทธ์ จันทร์โอชา"
                      },
                      "en": {
                          "title": "พลเอก",
                          "firstName": "ประยุทธ์",
                          "middleName": "",
                          "lastName": "จันทร์โอชา",
                          "fullName": "พลเอกประยุทธ์ จันทร์โอชา"
                      }
                  },
                  "images": {
                      "squareCenterUrl": "https://election66-asset.thaipbs.or.th/presenters-formation/squareCenter/22.png",
                      "centerUrl": "https://election66-asset.thaipbs.or.th/presenters-formation/center/22.png",
                      "motionHEVCUrl": "https://election66-asset.thaipbs.or.th/presenters/motionHEVC/22.mp4",
                      "motionWEBMUrl": "https://election66-asset.thaipbs.or.th/presenters/motionWEBM/22.webm"
                  },
                  "imagesCard": {
                      "centerUrl": "https://election66-asset.thaipbs.or.th/presenters/center/22.png",
                      "motionHEVCUrl": "https://election66-asset.thaipbs.or.th/presenters/motionHEVC/22.mp4",
                      "motionWEBMUrl": "https://election66-asset.thaipbs.or.th/presenters/motionWEBM/22.webm"
                  }
              }
          ]
      },
      {
          "refCode": "23",
          "number": "23",
          "color": "#ed1b23",
          "name": "รวมใจไทย",
          "translations": {
              "th": {
                  "name": "รวมใจไทย"
              },
              "en": {
                  "name": "Ruam Jai Thai"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/23.png"
          },
          "presenters": []
      },
      {
          "refCode": "24",
          "number": "24",
          "color": "#65B5A7",
          "name": "เพื่อชาติ",
          "translations": {
              "th": {
                  "name": "เพื่อชาติ"
              },
              "en": {
                  "name": "For The National"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/24.png"
          },
          "presenters": []
      },
      {
          "refCode": "25",
          "number": "25",
          "color": "#E1AC23",
          "name": "เสรีรวมไทย",
          "translations": {
              "th": {
                  "name": "เสรีรวมไทย"
              },
              "en": {
                  "name": "Thai Liberal"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/25.png"
          },
          "presenters": [
              {
                  "translations": {
                      "th": {
                          "title": "พลตำรวจเอก",
                          "firstName": "เสรีพิศุทธ์",
                          "middleName": "",
                          "lastName": "เตมียเวส",
                          "fullName": "พลตำรวจเอกเสรีพิศุทธ์ เตมียเวส"
                      },
                      "en": {
                          "title": "พลตำรวจเอก",
                          "firstName": "เสรีพิศุทธ์",
                          "middleName": "",
                          "lastName": "เตมียเวส",
                          "fullName": "พลตำรวจเอกเสรีพิศุทธ์ เตมียเวส"
                      }
                  },
                  "images": {
                      "squareCenterUrl": "https://election66-asset.thaipbs.or.th/presenters-formation/squareCenter/25.png",
                      "centerUrl": "https://election66-asset.thaipbs.or.th/presenters-formation/center/25.png",
                      "motionHEVCUrl": "https://election66-asset.thaipbs.or.th/presenters/motionHEVC/25.mp4",
                      "motionWEBMUrl": "https://election66-asset.thaipbs.or.th/presenters/motionWEBM/25.webm"
                  },
                  "imagesCard": {
                      "centerUrl": "https://election66-asset.thaipbs.or.th/presenters/center/25.png",
                      "motionHEVCUrl": "https://election66-asset.thaipbs.or.th/presenters/motionHEVC/25.mp4",
                      "motionWEBMUrl": "https://election66-asset.thaipbs.or.th/presenters/motionWEBM/25.webm"
                  }
              }
          ]
      },
      {
          "refCode": "26",
          "number": "26",
          "color": "#2FA3E1",
          "name": "ประชาธิปัตย์",
          "translations": {
              "th": {
                  "name": "ประชาธิปัตย์"
              },
              "en": {
                  "name": "Democrat"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/26.png"
          },
          "presenters": [
              {
                  "translations": {
                      "th": {
                          "title": "นาย",
                          "firstName": "จุรินทร์",
                          "middleName": "",
                          "lastName": "ลักษณวิศิษฏ์",
                          "fullName": "จุรินทร์ ลักษณวิศิษฏ์"
                      },
                      "en": {
                          "title": "นาย",
                          "firstName": "จุรินทร์",
                          "middleName": "",
                          "lastName": "ลักษณวิศิษฏ์",
                          "fullName": "จุรินทร์ ลักษณวิศิษฏ์"
                      }
                  },
                  "images": {
                      "squareCenterUrl": "https://election66-asset.thaipbs.or.th/presenters-formation/squareCenter/26.png",
                      "centerUrl": "https://election66-asset.thaipbs.or.th/presenters-formation/center/26.png",
                      "motionHEVCUrl": "https://election66-asset.thaipbs.or.th/presenters/motionHEVC/26.mp4",
                      "motionWEBMUrl": "https://election66-asset.thaipbs.or.th/presenters/motionWEBM/26.webm"
                  },
                  "imagesCard": {
                      "centerUrl": "https://election66-asset.thaipbs.or.th/presenters/center/26.png",
                      "motionHEVCUrl": "https://election66-asset.thaipbs.or.th/presenters/motionHEVC/26.mp4",
                      "motionWEBMUrl": "https://election66-asset.thaipbs.or.th/presenters/motionWEBM/26.webm"
                  }
              }
          ]
      },
      {
          "refCode": "27",
          "number": "27",
          "color": "#33398b",
          "name": "พลังธรรมใหม่",
          "translations": {
              "th": {
                  "name": "พลังธรรมใหม่"
              },
              "en": {
                  "name": "New Palangdharma"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/27.png"
          },
          "presenters": []
      },
      {
          "refCode": "28",
          "number": "28",
          "color": "#00b423",
          "name": "ไทยพร้อม",
          "translations": {
              "th": {
                  "name": "ไทยพร้อม"
              },
              "en": {
                  "name": "Thai Prompt"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/28.png"
          },
          "presenters": []
      },
      {
          "refCode": "29",
          "number": "29",
          "color": "#E13232",
          "name": "เพื่อไทย",
          "translations": {
              "th": {
                  "name": "เพื่อไทย"
              },
              "en": {
                  "name": "Pheu Thai"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/29.png"
          },
          "presenters": [
              {
                  "translations": {
                      "th": {
                          "title": "นางสาว",
                          "firstName": "แพทองธาร",
                          "middleName": "",
                          "lastName": "ชินวัตร",
                          "fullName": "แพทองธาร ชินวัตร"
                      },
                      "en": {
                          "title": "นางสาว",
                          "firstName": "แพทองธาร",
                          "middleName": "",
                          "lastName": "ชินวัตร",
                          "fullName": "แพทองธาร ชินวัตร"
                      }
                  },
                  "images": {
                      "squareCenterUrl": "https://election66-asset.thaipbs.or.th/presenters-formation/squareCenter/29.png",
                      "centerUrl": "https://election66-asset.thaipbs.or.th/presenters-formation/center/29.png",
                      "motionHEVCUrl": "https://election66-asset.thaipbs.or.th/presenters/motionHEVC/29.mp4",
                      "motionWEBMUrl": "https://election66-asset.thaipbs.or.th/presenters/motionWEBM/29.webm"
                  },
                  "imagesCard": {
                      "centerUrl": "https://election66-asset.thaipbs.or.th/presenters/center/29.png",
                      "motionHEVCUrl": "https://election66-asset.thaipbs.or.th/presenters/motionHEVC/29.mp4",
                      "motionWEBMUrl": "https://election66-asset.thaipbs.or.th/presenters/motionWEBM/29.webm"
                  }
              }
          ]
      },
      {
          "refCode": "30",
          "number": "30",
          "color": "#0040bc",
          "name": "ทางเลือกใหม่",
          "translations": {
              "th": {
                  "name": "ทางเลือกใหม่"
              },
              "en": {
                  "name": "New Alternative"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/30.png"
          },
          "presenters": []
      },
      {
          "refCode": "31",
          "number": "31",
          "color": "#FF7F2B",
          "name": "ก้าวไกล",
          "translations": {
              "th": {
                  "name": "ก้าวไกล"
              },
              "en": {
                  "name": "Move Forward"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/31.png"
          },
          "presenters": [
              {
                  "translations": {
                      "th": {
                          "title": "นาย",
                          "firstName": "พิธา",
                          "middleName": "",
                          "lastName": "ลิ้มเจริญรัตน์",
                          "fullName": "พิธา ลิ้มเจริญรัตน์"
                      },
                      "en": {
                          "title": "นาย",
                          "firstName": "พิธา",
                          "middleName": "",
                          "lastName": "ลิ้มเจริญรัตน์",
                          "fullName": "พิธา ลิ้มเจริญรัตน์"
                      }
                  },
                  "images": {
                      "squareCenterUrl": "https://election66-asset.thaipbs.or.th/presenters-formation/squareCenter/31.png",
                      "centerUrl": "https://election66-asset.thaipbs.or.th/presenters-formation/center/31.png",
                      "motionHEVCUrl": "https://election66-asset.thaipbs.or.th/presenters/motionHEVC/31.mp4",
                      "motionWEBMUrl": "https://election66-asset.thaipbs.or.th/presenters/motionWEBM/31.webm"
                  },
                  "imagesCard": {
                      "centerUrl": "https://election66-asset.thaipbs.or.th/presenters/center/31.png",
                      "motionHEVCUrl": "https://election66-asset.thaipbs.or.th/presenters/motionHEVC/31.mp4",
                      "motionWEBMUrl": "https://election66-asset.thaipbs.or.th/presenters/motionWEBM/31.webm"
                  }
              }
          ]
      },
      {
          "refCode": "32",
          "number": "32",
          "color": "#16098C",
          "name": "ไทยสร้างไทย",
          "translations": {
              "th": {
                  "name": "ไทยสร้างไทย"
              },
              "en": {
                  "name": "Thai Sang Thai"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/32.png"
          },
          "presenters": [
              {
                  "translations": {
                      "th": {
                          "title": "นาง",
                          "firstName": "สุดารัตน์",
                          "middleName": "",
                          "lastName": "เกยุราพันธุ์",
                          "fullName": "สุดารัตน์ เกยุราพันธุ์"
                      },
                      "en": {
                          "title": "นาง",
                          "firstName": "สุดารัตน์",
                          "middleName": "",
                          "lastName": "เกยุราพันธุ์",
                          "fullName": "สุดารัตน์ เกยุราพันธุ์"
                      }
                  },
                  "images": {
                      "squareCenterUrl": "https://election66-asset.thaipbs.or.th/presenters-formation/squareCenter/32.png",
                      "centerUrl": "https://election66-asset.thaipbs.or.th/presenters-formation/center/32.png",
                      "motionHEVCUrl": "https://election66-asset.thaipbs.or.th/presenters/motionHEVC/32.mp4",
                      "motionWEBMUrl": "https://election66-asset.thaipbs.or.th/presenters/motionWEBM/32.webm"
                  },
                  "imagesCard": {
                      "centerUrl": "https://election66-asset.thaipbs.or.th/presenters/center/32.png",
                      "motionHEVCUrl": "https://election66-asset.thaipbs.or.th/presenters/motionHEVC/32.mp4",
                      "motionWEBMUrl": "https://election66-asset.thaipbs.or.th/presenters/motionWEBM/32.webm"
                  }
              }
          ]
      },
      {
          "refCode": "33",
          "number": "33",
          "color": "#7c4c9e",
          "name": "ไทยเป็นหนึ่ง",
          "translations": {
              "th": {
                  "name": "ไทยเป็นหนึ่ง"
              },
              "en": {
                  "name": "Thai To Be One"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/33.png"
          },
          "presenters": []
      },
      {
          "refCode": "34",
          "number": "34",
          "color": "#ba7f18",
          "name": "แผ่นดินธรรม",
          "translations": {
              "th": {
                  "name": "แผ่นดินธรรม"
              },
              "en": {
                  "name": "The Land Of Dharma"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/34.png"
          },
          "presenters": []
      },
      {
          "refCode": "35",
          "number": "35",
          "color": "#672d92",
          "name": "รวมพลัง",
          "translations": {
              "th": {
                  "name": "รวมพลัง"
              },
              "en": {
                  "name": "Action Coalition"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/35.png"
          },
          "presenters": []
      },
      {
          "refCode": "36",
          "number": "36",
          "color": "#fced00",
          "name": "เพื่อชาติไทย",
          "translations": {
              "th": {
                  "name": "เพื่อชาติไทย"
              },
              "en": {
                  "name": "Pheu Chart Thai"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/36.png"
          },
          "presenters": []
      },
      {
          "refCode": "37",
          "number": "37",
          "color": "#2A5DAA",
          "name": "พลังประชารัฐ",
          "translations": {
              "th": {
                  "name": "พลังประชารัฐ"
              },
              "en": {
                  "name": "Palang Pracharath"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/37.png"
          },
          "presenters": [
              {
                  "translations": {
                      "th": {
                          "title": "พลเอก",
                          "firstName": "ประวิทย์",
                          "middleName": "",
                          "lastName": "วงศ์สุวรรณ",
                          "fullName": "ประวิทย์ วงษ์สุวรรณ"
                      },
                      "en": {
                          "title": "พลเอก",
                          "firstName": "ประวิทย์",
                          "middleName": "",
                          "lastName": "วงศ์สุวรรณ",
                          "fullName": "ประวิทย์ วงษ์สุวรรณ"
                      }
                  },
                  "images": {
                      "squareCenterUrl": "https://election66-asset.thaipbs.or.th/presenters-formation/squareCenter/37.png",
                      "centerUrl": "https://election66-asset.thaipbs.or.th/presenters-formation/center/37.png",
                      "motionHEVCUrl": "https://election66-asset.thaipbs.or.th/presenters/motionHEVC/37.mp4",
                      "motionWEBMUrl": "https://election66-asset.thaipbs.or.th/presenters/motionWEBM/37.webm"
                  },
                  "imagesCard": {
                      "centerUrl": "https://election66-asset.thaipbs.or.th/presenters/center/37.png",
                      "motionHEVCUrl": "https://election66-asset.thaipbs.or.th/presenters/motionHEVC/37.mp4",
                      "motionWEBMUrl": "https://election66-asset.thaipbs.or.th/presenters/motionWEBM/37.webm"
                  }
              }
          ]
      },
      {
          "refCode": "38",
          "number": "38",
          "color": "#4c5694",
          "name": "เพื่อไทรวมพลัง",
          "translations": {
              "th": {
                  "name": "เพื่อไทรวมพลัง"
              },
              "en": {
                  "name": "Peu Thai Rumphalang"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/38.png"
          },
          "presenters": []
      },
      {
          "refCode": "39",
          "number": "39",
          "color": "#71be44",
          "name": "มิติใหม่",
          "translations": {
              "th": {
                  "name": "มิติใหม่"
              },
              "en": {
                  "name": "New Dimension"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/39.png"
          },
          "presenters": []
      },
      {
          "refCode": "40",
          "number": "40",
          "color": "#fda000",
          "name": "ประชาภิวัฒน์",
          "translations": {
              "th": {
                  "name": "ประชาภิวัฒน์"
              },
              "en": {
                  "name": "People Progressive"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/40.png"
          },
          "presenters": []
      },
      {
          "refCode": "41",
          "number": "41",
          "color": "#663290",
          "name": "ไทยธรรม",
          "translations": {
              "th": {
                  "name": "ไทยธรรม"
              },
              "en": {
                  "name": "Thai Morality"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/41.png"
          },
          "presenters": []
      },
      {
          "refCode": "42",
          "number": "42",
          "color": "#ad811d",
          "name": "ไทยศรีวิไลย์",
          "translations": {
              "th": {
                  "name": "ไทยศรีวิไลย์"
              },
              "en": {
                  "name": "Thai Civilized"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/42.png"
          },
          "presenters": []
      },
      {
          "refCode": "43",
          "number": "43",
          "color": "#00acee",
          "name": "พลังสหกรณ์",
          "translations": {
              "th": {
                  "name": "พลังสหกรณ์"
              },
              "en": {
                  "name": "Cooperative Power"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/43.png"
          },
          "presenters": []
      },
      {
          "refCode": "44",
          "number": "44",
          "color": "#1e339a",
          "name": "ราษฎร์วิถี",
          "translations": {
              "th": {
                  "name": "ราษฎร์วิถี"
              },
              "en": {
                  "name": "Rat Withi"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/44.png"
          },
          "presenters": []
      },
      {
          "refCode": "45",
          "number": "45",
          "color": "#98090D",
          "name": "แนวทางใหม่",
          "translations": {
              "th": {
                  "name": "แนวทางใหม่"
              },
              "en": {
                  "name": "The New Way"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/45.png"
          },
          "presenters": []
      },
      {
          "refCode": "46",
          "number": "46",
          "color": "#57235a",
          "name": "ถิ่นกาขาวชาววิไล",
          "translations": {
              "th": {
                  "name": "ถิ่นกาขาวชาววิไล"
              },
              "en": {
                  "name": "Thinkakhao Chaovilai"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/46.png"
          },
          "presenters": []
      },
      {
          "refCode": "47",
          "number": "47",
          "color": "#0518d4",
          "name": "รวมแผ่นดิน",
          "translations": {
              "th": {
                  "name": "รวมแผ่นดิน"
              },
              "en": {
                  "name": "Thailand Together"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/47.png"
          },
          "presenters": []
      },
      {
          "refCode": "48",
          "number": "48",
          "color": "#fe0000",
          "name": "เพื่ออนาคตไทย",
          "translations": {
              "th": {
                  "name": "เพื่ออนาคตไทย"
              },
              "en": {
                  "name": "For The Thai Future"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/48.png"
          },
          "presenters": []
      },
      {
          "refCode": "49",
          "number": "49",
          "color": "#8fc440",
          "name": "รักษ์ผืนป่าประเทศไทย",
          "translations": {
              "th": {
                  "name": "รักษ์ผืนป่าประเทศไทย"
              },
              "en": {
                  "name": "Thai Forest Conservation"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/49.png"
          },
          "presenters": []
      },
      {
          "refCode": "50",
          "number": "50",
          "color": "#fe0002",
          "name": "พลังปวงชนไทย",
          "translations": {
              "th": {
                  "name": "พลังปวงชนไทย"
              },
              "en": {
                  "name": "Thai People Power"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/50.png"
          },
          "presenters": []
      },
      {
          "refCode": "51",
          "number": "51",
          "color": "#d70000",
          "name": "สามัญชน",
          "translations": {
              "th": {
                  "name": "สามัญชน"
              },
              "en": {
                  "name": "Commoners"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/51.png"
          },
          "presenters": []
      },
      {
          "refCode": "52",
          "number": "52",
          "color": "#1a2179",
          "name": "ชาติรุ่งเรือง",
          "translations": {
              "th": {
                  "name": "ชาติรุ่งเรือง"
              },
              "en": {
                  "name": "Chat Rung Rueang"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/52.png"
          },
          "presenters": []
      },
      {
          "refCode": "53",
          "number": "53",
          "color": "#0530a1",
          "name": "พลังสังคม",
          "translations": {
              "th": {
                  "name": "พลังสังคม"
              },
              "en": {
                  "name": "Social Power"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/53.png"
          },
          "presenters": []
      },
      {
          "refCode": "54",
          "number": "54",
          "color": "#027a3d",
          "name": "ภราดรภาพ",
          "translations": {
              "th": {
                  "name": "ภราดรภาพ"
              },
              "en": {
                  "name": "Paradonraphab"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/54.png"
          },
          "presenters": []
      },
      {
          "refCode": "55",
          "number": "55",
          "color": "#3059a8",
          "name": "ไทยก้าวหน้า",
          "translations": {
              "th": {
                  "name": "ไทยก้าวหน้า"
              },
              "en": {
                  "name": "Thai Progress"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/55.png"
          },
          "presenters": []
      },
      {
          "refCode": "56",
          "number": "56",
          "color": "#0741fa",
          "name": "ประชาไทย",
          "translations": {
              "th": {
                  "name": "ประชาไทย"
              },
              "en": {
                  "name": "Thai Population"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/56.png"
          },
          "presenters": []
      },
      {
          "refCode": "57",
          "number": "57",
          "color": "#292b78",
          "name": "พลังเพื่อไทย",
          "translations": {
              "th": {
                  "name": "พลังเพื่อไทย"
              },
              "en": {
                  "name": "Power Thai"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/57.png"
          },
          "presenters": []
      },
      {
          "refCode": "58",
          "number": "58",
          "color": "#e80317",
          "name": "สังคมประชาธิปไตยไทย",
          "translations": {
              "th": {
                  "name": "สังคมประชาธิปไตยไทย"
              },
              "en": {
                  "name": "Thai Social Democratic"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/58.png"
          },
          "presenters": []
      },
      {
          "refCode": "59",
          "number": "59",
          "color": "#1c0780",
          "name": "ช่วยชาติ",
          "translations": {
              "th": {
                  "name": "ช่วยชาติ"
              },
              "en": {
                  "name": "Save The Nation"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/59.png"
          },
          "presenters": []
      },
      {
          "refCode": "60",
          "number": "60",
          "color": "#f6ef01",
          "name": "ความหวังใหม่",
          "translations": {
              "th": {
                  "name": "ความหวังใหม่"
              },
              "en": {
                  "name": "New Aspiration"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/60.png"
          },
          "presenters": []
      },
      {
          "refCode": "61",
          "number": "61",
          "color": "#006cb7",
          "name": "คลองไทย",
          "translations": {
              "th": {
                  "name": "คลองไทย"
              },
              "en": {
                  "name": "Klong Thai"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/61.png"
          },
          "presenters": []
      },
      {
          "refCode": "62",
          "number": "62",
          "color": "#66ca34",
          "name": "พลังไทยรักชาติ",
          "translations": {
              "th": {
                  "name": "พลังไทยรักชาติ"
              },
              "en": {
                  "name": "Palang Thai Rak Chart"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/62.png"
          },
          "presenters": []
      },
      {
          "refCode": "63",
          "number": "63",
          "color": "#284EDF",
          "name": "ประชากรไทย",
          "translations": {
              "th": {
                  "name": "ประชากรไทย"
              },
              "en": {
                  "name": "Thai Citizen"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/63.png"
          },
          "presenters": []
      },
      {
          "refCode": "64",
          "number": "64",
          "color": "#060709",
          "name": "เส้นด้าย",
          "translations": {
              "th": {
                  "name": "เส้นด้าย"
              },
              "en": {
                  "name": "Zendai"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/64.png"
          },
          "presenters": []
      },
      {
          "refCode": "65",
          "number": "65",
          "color": "#0919c6",
          "name": "เปลี่ยนอนาคต",
          "translations": {
              "th": {
                  "name": "เปลี่ยนอนาคต"
              },
              "en": {
                  "name": "Change Future"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/65.png"
          },
          "presenters": []
      },
      {
          "refCode": "66",
          "number": "66",
          "color": "#c82e22",
          "name": "พลังประชาธิปไตย",
          "translations": {
              "th": {
                  "name": "พลังประชาธิปไตย"
              },
              "en": {
                  "name": "Democratic Force"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/66.png"
          },
          "presenters": []
      },
      {
          "refCode": "67",
          "number": "67",
          "color": "#01bf65",
          "name": "ไทยสมาร์ท",
          "translations": {
              "th": {
                  "name": "ไทยสมาร์ท"
              },
              "en": {
                  "name": "Thai Smart"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/67.png"
          },
          "presenters": []
      },
      {
          "refCode": "1224",
          "number": "68",
          "color": "#161D42",
          "name": "ยุทธศาสตร์ชาติ",
          "translations": {
              "th": {
                  "name": "ยุทธศาสตร์ชาติ"
              },
              "en": {
                  "name": "National Strategy"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/1224.png"
          },
          "presenters": []
      },
      {
          "refCode": "1218",
          "number": "69",
          "color": "#2C3191",
          "name": "เศรษฐกิจไทย",
          "translations": {
              "th": {
                  "name": "เศรษฐกิจไทย"
              },
              "en": {
                  "name": "Thai Economic"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/1218.png"
          },
          "presenters": []
      },
      {
          "refCode": "1219",
          "number": "70",
          "color": "#d32222",
          "name": "เส้นทางใหม่",
          "translations": {
              "th": {
                  "name": "เส้นทางใหม่"
              },
              "en": {
                  "name": "New Direction"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/1219.png"
          },
          "presenters": []
      },
      {
          "refCode": "1022",
          "number": "71",
          "color": "#0A337C",
          "name": "เครือข่ายชาวนาแห่งประเทศไทย",
          "translations": {
              "th": {
                  "name": "เครือข่ายชาวนาแห่งประเทศไทย"
              },
              "en": {
                  "name": "The Farmer Network Of Thailand"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/1022.png"
          },
          "presenters": []
      },
      {
          "refCode": "1091",
          "number": "72",
          "color": "#F54B27",
          "name": "ประชาสามัคคี",
          "translations": {
              "th": {
                  "name": "ประชาสามัคคี"
              },
              "en": {
                  "name": "Harmony People"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/1091.png"
          },
          "presenters": []
      },
      {
          "refCode": "1112",
          "number": "73",
          "color": "#1D90FF",
          "name": "พลังบูรพา",
          "translations": {
              "th": {
                  "name": "พลังบูรพา"
              },
              "en": {
                  "name": "Palang Burapha"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/1112.png"
          },
          "presenters": []
      },
      {
          "refCode": "1124",
          "number": "74",
          "color": "#15A662",
          "name": "พลังท้องถิ่นไท",
          "translations": {
              "th": {
                  "name": "พลังท้องถิ่นไท"
              },
              "en": {
                  "name": "Thai Local Power"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/1124.png"
          },
          "presenters": []
      },
      {
          "refCode": "1147",
          "number": "75",
          "color": "#3B912E",
          "name": "รักษ์ธรรม",
          "translations": {
              "th": {
                  "name": "รักษ์ธรรม"
              },
              "en": {
                  "name": "Rakstham"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/1147.png"
          },
          "presenters": []
      },
      {
          "refCode": "1174",
          "number": "76",
          "color": "#00008B",
          "name": "สร้างอนาคตไทย",
          "translations": {
              "th": {
                  "name": "สร้างอนาคตไทย"
              },
              "en": {
                  "name": "Futurise Thailand"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/1174.png"
          },
          "presenters": []
      },
      {
          "refCode": "1182",
          "number": "77",
          "color": "#2D1A56",
          "name": "พลังไทรุ่งเรือง",
          "translations": {
              "th": {
                  "name": "พลังไทรุ่งเรือง"
              },
              "en": {
                  "name": "Glory Thai Power"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/1182.png"
          },
          "presenters": []
      },
      {
          "refCode": "1193",
          "number": "78",
          "color": "#0F4579",
          "name": "เศรษฐกิจใหม่",
          "translations": {
              "th": {
                  "name": "เศรษฐกิจใหม่"
              },
              "en": {
                  "name": "New Economics"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/1193.png"
          },
          "presenters": []
      },
      {
          "refCode": "1213",
          "number": "79",
          "color": "#C1687A",
          "name": "รวมไทยยูไนเต็ด",
          "translations": {
              "th": {
                  "name": "รวมไทยยูไนเต็ด"
              },
              "en": {
                  "name": "Ruam Thai United"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/1213.png"
          },
          "presenters": []
      },
      {
          "refCode": "1222",
          "number": "80",
          "color": "#204181",
          "name": "กล้า",
          "translations": {
              "th": {
                  "name": "กล้า"
              },
              "en": {
                  "name": "Kla"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/1222.png"
          },
          "presenters": []
      },
      {
          "refCode": "1231",
          "number": "81",
          "color": "#2E3191",
          "name": "เพื่อประชาชน",
          "translations": {
              "th": {
                  "name": "เพื่อประชาชน"
              },
              "en": {
                  "name": "Pheu Prachachon"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/1231.png"
          },
          "presenters": []
      },
      {
          "refCode": "1248",
          "number": "82",
          "color": "#FFC002",
          "name": "สยามพล",
          "translations": {
              "th": {
                  "name": "สยามพล"
              },
              "en": {
                  "name": "Siampon"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/1248.png"
          },
          "presenters": []
      },
      {
          "refCode": "1251",
          "number": "83",
          "color": "#FBD002",
          "name": "สัมมาธิปไตย",
          "translations": {
              "th": {
                  "name": "สัมมาธิปไตย"
              },
              "en": {
                  "name": "Sammaa Sovereign"
              }
          },
          "images": {
              "logoImageUrl": "https://election66-asset.thaipbs.or.th/party-logo-images/1251.png"
          },
          "presenters": []
      }
  ]
}

export const parties = keyBy(partiesList.data, (party) => party.refCode)
