import { ActionIcon, Badge, Button, Card, Flex, Group, MantineProvider, MultiSelect, Select, Text, Title } from '@mantine/core';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useFirebaseField } from '../../../hooks/useFirebaseField';
import { useParams } from 'react-router';

const StudioContainer = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: stretch;
  flex-direction: row;

  cursor: pointer;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const ControlPanel = styled.div`
  flex: 1;
  padding: 32px;

  border-right: solid 1px #303030;
`;

const AddItemContainer = styled.div`
  margin-top: 32px;
`;

const PlaylistPanel = styled.div`
  flex: 1;
  overflow: scroll;
`;


const PlaylistList = styled.div`
  flex: 1;
  overflow: scroll;
`;

const PreviewPanel = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  padding: 32px;
`;

const LivePanel = styled.div`
  display: flex;
  flex-direction: column;
  background: #101010;

  padding: 32px;
`;

const PreviewHeaderContainer = styled.div`
  margin-bottom: 16px;
`;

const PreviewFrame = styled.iframe`
  width: 480px;
  height: 270px;

  overflow: hidden;
`

export const AreaPill = ({ areaRefCode }) => {
  const areaName = useSelector(state => areaRefCode ? state.areaReducer.areasByRefCode[areaRefCode].translations.th.fullName : '');

  return (
    <span>{areaName}</span>
  )
}

export const ProvincePill = ({ provinceRefCode }) => {
  const provienceName = useSelector(state => provinceRefCode ? state.provinceReducer.provincesByRefCode[provinceRefCode].translations.th.name : '');

  return (
    <span>{provienceName}</span>
  )
}

export const RegionPill = ({ regionRefCode }) => {
  const regionName = useSelector(state => regionRefCode ? state.regionReducer.regionsByRefCode[regionRefCode].name : '');

  return (
    <span>{regionName}</span>
  )
}

export const SceneTemplateItem = ({ code, isPreview, isLive, onClick }) => {
  const sceneTemplate = useSelector(state => state.sceneTemplateReducer.sceneTemplatesByCode[code]);

  return (<Card mt="xs" onClick={() => onClick(sceneTemplate)}>
    <Group position="apart">
      <Text>{sceneTemplate?.title ?? 'Unknown Item'}</Text>
      {
        isLive
          ? <Badge color="red">LIVE</Badge>
          : null
      }
      {
        isPreview && !isLive
          ? <Badge color="lime">Preview</Badge>
          : null
      }
    </Group>
  </Card>);
}

export const PlaylistItem = ({ item, isPreview, isLive, onClick, onClickDelete }) => {
  const sceneTemplate = useSelector(state => state.sceneTemplateReducer.sceneTemplatesByCode[item.sceneTemplateCode]);

  return (<Card mt="xs" onClick={() => onClick(item)}>
    <Flex>
      <div style={{'width': '80px'}}>
        {
          isLive
            ? <Badge color="red">LIVE</Badge>
            : null
        }
        {
          isPreview && !isLive
            ? <Badge color="lime">Preview</Badge>
            : null
        }
      </div>
      <Flex direction="column" style={{flexGrow: 1}}>
        <Text>{sceneTemplate?.title ?? 'Unknown Item'}</Text>
        <Flex direction="row">
          {
            item.params?.areaRefCode
              ? <AreaPill areaRefCode={item.params?.areaRefCode} />
              : null
          }
          {
            item.params?.provinceRefCode
              ? <ProvincePill provinceRefCode={item.params?.provinceRefCode} />
              : null
          }
          {
            item.params?.regionRefCode
              ? <RegionPill regionRefCode={item.params?.regionRefCode} />
              : null
          }
        </Flex>
      </Flex>
      <ActionIcon
        size="xs"
        color="red"
        onClick={(e) => { e.preventDefault(); onClickDelete(item); } }>
        X
      </ActionIcon>
    </Flex>
  </Card>);
}

export const StudioPage = () => {
  const { stageCode } = useParams()
  const areaOptions = useSelector((state) => state.areaReducer.allAreaOptions);
  const provinceOptions = useSelector((state) => state.provinceReducer.allProvinceOptions);
  const regionOptions = useSelector((state) => state.regionReducer.allRegionOptions);
  const sceneTemplateCodes = useSelector((state) => state.sceneTemplateReducer.sceneTemplateCodes);
  const sceneTemplatesByCode = useSelector((state) => state.sceneTemplateReducer.sceneTemplatesByCode);

  const [liveScene, setLiveScene] = useFirebaseField(`stages/${stageCode}/liveScene`, {
    sceneTemplateCode: 'emptyScene',
    params: {},
  });
  const [previewScene, setPreviewScene] = useFirebaseField(`stages/${stageCode}/previewScene`, {
    sceneTemplateCode: 'emptyScene',
    params: {},
  });
  const [playlist, setPlaylist] = useFirebaseField(`stages/${stageCode}/playlist`, {
    currentItemId: '',
    items: [],
  });

  const onClickSceneTemplateItem = (sceneTemplate) => {
    setPreviewScene({
      id: `scene-${Date.now()}`,
      sceneTemplateCode: sceneTemplate.code,
      params: { ...sceneTemplate.defaultParams },
    });
  }

  const onChangeAreaDropdown = (value) => {
    if (!value) {
      return;
    }

    setPreviewScene({
      ...previewScene,
      params: {
        ...previewScene.params,
        areaRefCode: value,
      },
    });
  }

  const onChangeProvinceDropdown = (value) => {
    if (!value) {
      return;
    }

    setPreviewScene({
      ...previewScene,
      params: {
        ...previewScene.params,
        provinceRefCode: value,
      },
    });
  }

  const onChangeRegionDropdown = (value) => {
    if (!value) {
      return;
    }

    setPreviewScene({
      ...previewScene,
      params: {
        ...previewScene.params,
        regionRefCode: value,
      },
    });
  }

  const onClickLiveButton = () => {
    setLiveScene({
      ...previewScene,
    });
  }

  const onClickAppendToPlaylistButton = () => {
    const newItem = {
      ...previewScene,
    };
    setPlaylist({
      items: [
        ...playlist?.items ?? [],
        newItem,
      ],
    });

    const newPreviewScene = {
      ...previewScene,
      id: `scene-${Date.now()}`,
    }
    setPreviewScene(newPreviewScene)
  }

  const onClickPreviewPlaylistItem = (scene) => {
    setPreviewScene({
      ...scene
    });
  }

  const onRemovePlaylistItem = (itemToRemove) => {
    if (!playlist) {
      return;
    }

    const index = playlist.items.indexOf(itemToRemove);
    if (index < 0) {
      return;
    }

    const newItems = [
      ...playlist.items.slice(0, index),
      ...playlist.items.slice(index + 1),
    ];
    if (newItems.length <= 0) {
      setPlaylist({
        items: newItems,
      });
      return;
    }

    setPlaylist({
      items: newItems,
    });
  }

  return (
    <MantineProvider theme={{ colorScheme: 'dark' }} withGlobalStyles withNormalizeCSS>
      <StudioContainer>
        <ControlPanel>
          <Flex direction="column">
            <Title order={2} mb="md">Studio: {stageCode}</Title>
            <Title order={3}>(1) เลือกซีน</Title>
            {
              sceneTemplateCodes.map((code) => (
                <SceneTemplateItem key={code} code={code}
                  isPreview={code === previewScene.sceneTemplateCode}
                  onClick={onClickSceneTemplateItem}
                />
              ))
            }
          </Flex>
          <AddItemContainer mt="xl">
            <Title order={3} mb="md" mt="xl">(2) ตั้งค่าซีนที่เลือก</Title>
            {
              previewScene?.params?.areaRefCode
                ? (
                  <>
                    <Select
                      searchable
                      label="เขตเลือกตั้ง"
                      placeholder="เลือกเขตเลือกตั้ง"
                      data={areaOptions}
                      onChange={onChangeAreaDropdown}
                    />
                  </>
                )
                : null
            }
            {
              previewScene?.params?.provinceRefCode
                ? (
                  <>
                    <Select
                      searchable
                      label="จังหวัด"
                      placeholder="เลือกจังหวัด"
                      data={provinceOptions}
                      onChange={onChangeProvinceDropdown}
                    />
                  </>
                )
                : null
            }
            {
              previewScene?.params?.regionRefCode
                ? (
                  <>
                    <Select
                      searchable
                      label="ภาค"
                      placeholder="เลือกภาค"
                      data={regionOptions}
                      onChange={onChangeRegionDropdown}
                    />
                  </>
                )
                : null
            }
            {
              Object.keys(previewScene?.params ?? {}).length <= 0
                ? <span>ไม่มีการตั้งค่าสำหรับซีนนี้</span>
                : null
            }
          </AddItemContainer>
          <Flex gap="md" mt="xl" style={{'align-self': 'last baseline'}}>
            <Button type="button" fullWidth  onClick={onClickAppendToPlaylistButton}>เพิ่มเข้าคิว</Button>
            <Button type="button" variant="outline" color="red" onClick={onClickLiveButton}>ส่งขึ้น LIVE ทันที</Button>
          </Flex>
        </ControlPanel>
        <PreviewPanel>
          <PlaylistPanel>
            <Title mt={'md'} order={3}>คิว</Title>
            <Flex direction="column">
              {
                (playlist?.items || []).map(item => (
                  <PlaylistItem key={item.id}
                    item={item}
                    isLive={item.id === liveScene?.id}
                    isPreview={item.id === previewScene?.id}
                    scene={sceneTemplatesByCode[item.sceneCode]}
                    onClick={onClickPreviewPlaylistItem}
                    onClickDelete={onRemovePlaylistItem}
                  />
                ))
              }
            </Flex>
          </PlaylistPanel>
        </PreviewPanel>
        <LivePanel>
          <PreviewHeaderContainer>
            <Badge size="xl" color="red">LIVE</Badge>
          </PreviewHeaderContainer>
          <PreviewFrame
            src={`/stage/${stageCode}/frames/live`}
            width="480"
            height="270"
            frameBorder="0"
            scrolling="no"
          />
          <Button color={'red'} mt={'lg'} mb={'lg'} size={'lg'} onClick={onClickLiveButton}>ส่ง Preview ขึ้น LIVE</Button>
          <PreviewHeaderContainer>
            <Badge size="xl" color="lime">Preview</Badge>
          </PreviewHeaderContainer>
          <PreviewFrame
            src={`/stage/${stageCode}/frames/preview`}
            width="480"
            height="270"
            frameBorder="0"
            scrolling="no"
          />
        </LivePanel>
      </StudioContainer>
    </MantineProvider>
  )
};
