import styled from 'styled-components';

export const PartyWrapper = styled.div`
  margin: 0 auto;
  flex: 1;

  .party-item-container {
    flex: 1;
  }
`;

export const TableWrapper = styled.div`
  padding: 0 52px;
  width: 100%;
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 2px;
  padding-top: 16px;
  padding-bottom: 8px;
  padding-right: 80px;
`;

export const TableHeaderText = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: var(--grey900);

  text-align: right;

  width: 300px;
`;
