export const container = {
  hidden: {
    transition: {
      duration: 1,
      staggerChildren: 0.02,
      staggerDirection: -1,
    },
  },
  show: {
    transition: {
      staggerChildren: 0.08,
    },
  },
};

export const containerVariants = {
  hidden: {},
  show: {},
};

export const itemVariants = (index) => ({
  hidden: {
    opacity: 0,
    y: 80,
    transition: { type: 'tween', duration: 2 },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: { type: 'tween', duration: 0.6, delay: index * 0.2 },
  },
});

export const itemTitleVariants = {
  hidden: {
    opacity: 0,
    y: 50,
  },
  show: {
    opacity: 1,
    y: 0,
  },
};
