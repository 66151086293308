import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
// import { useInView } from "react-intersection-observer";

import { parseParams } from '../../../utils/params';
import { useIntervalOrClick } from '../../../utils/effects';
import { CandidateCard } from '../../atoms/candidateCard/CandidateCard';
import { Counter } from '../../atoms/counter/Counter';
import { getDatabase, onValue, ref, set } from "firebase/database";

import {
  LowerBarContainer,
  TitleContainer,
  Container,
  ListWrapper,
  ListContainer,
  ListItem,
  // QRCode,
} from '../../templates/lowerBar/LowerBar.style';
import { BannerTitle, Logo } from './LowerBarDistrictsPage.style';

import { parties } from '../../../const/parties';
import { setAreaRegionIndex } from '../../../store/lowerthirdAreaResult/reducers';

import {
  container,
  containerVariants,
  itemTitleVariants,
  itemVariants
} from '../../templates/lowerBar/LowerBar';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';


// const getTotalItemHeight = (itemHeight, itemPerView) => {
//   return itemHeight * itemPerView;
// };

const LowerbarDistrictItem = ({ district }) => {
  const [params] = useSearchParams();
  const lang = params.get('lang');

  return district ? (
    <div
      style={{ width: '75%' }}
    >
      <motion.div
        variants={container}
        initial='hidden'
        animate='show'
        exit='hidden'
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ overflow: 'hidden' }}>
          {district.area.province.translations.th.name
            .split('')
            .map((char, index) => {
              return (
                <motion.span
                  key={char + '-' + index}
                  variants={itemTitleVariants}
                >
                  {char}
                </motion.span>
              );
            })}
        </div>
      </motion.div>
      <motion.div
        variants={container}
        initial='hidden'
        animate='show'
        exit='hidden'
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '16px',
        }}
      >
        <div
          style={{
            overflow: 'hidden',
            marginRight: '8px',
            fontSize: '24px',
            fontWeight: '700',
          }}
        >
          {`เขต ${district.area.areaNo}`
            .split('')
            .map((char, index) => {
              return (
                <motion.span
                  key={char + '-' + index}
                  variants={itemTitleVariants}
                >
                  {char}
                </motion.span>
              );
            })}
        </div>
        <div
          style={{
            overflow: 'hidden',
            fontSize: '18px',
            fontWeight: '700',
            color: 'var(--ciBlue)',
            marginTop: "4px",
          }}
        >
          {/** แก้ค่า to={??? || 0} */}
          {
            district.dataSource === 'ect'
              ? (
                <>
                  <span>{lang === 'en' ? `Counted` : `หน่วยที่นับแล้ว`}</span>
                  <Counter from={0} to={district.progress ?? 0} />
                  <span>%</span>
                </>
              )
              : <span>{lang === 'en' ? `(Source from ADTEB Volunteer)` : `(คะแนนจากอาสาฯ)`}</span>
          }

        </div>
      </motion.div>
    </div>
  ) : null;
}

const LowerbarDistrictItemRight = ({ district }) => {
  const [params] = useSearchParams();
  const lang = params.get('lang');

  return district ? (
    <ListContainer>
      {district?.leadingCandidateResults?.map((entry, index) => {
        return (
          <ListItem key={`candidate-${entry.candidateRefCode}-${index}`}>
            <motion.div
              variants={itemVariants(index)}
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'var(--white)',
              }}
            >
              <CandidateCard
                firstName={entry.candidate.translations[lang ?? 'th']?.firstName}
                lastName={entry.candidate.translations[lang ?? 'th']?.lastName}
                imgUrl={entry.candidate.images.portraitImageUrl}
                score={entry.totalVotes}
                bgColor={`${parties[entry.candidate.partyRefCode]?.color}`}
                logoUrl={`${parties[entry.candidate.partyRefCode]?.images.logoImageUrl}`}
              />
            </motion.div>
          </ListItem>
        );
      })}
    </ListContainer>
  ) : null;
}

export const LowerBarDistrictsPage = ({showQRCode = false}) => {
  const { t } = parseParams({ t: 9000 });

  const [params] = useSearchParams();
  const lang = params.get('lang');

  const { areaRefCode } = useParams()
  const resultsByAreaRefCode = useSelector((state) => {
    return state.districtVoteResultReducer.resultsByAreaRefCode;
  });

  return (
    <div className="page-container">
      <motion.div
        variants={containerVariants}
        initial='hidden'
        animate='show'
        exit='hidden'
        style={{
          width: '100%',
          // overflowY: 'clip',
          position: 'absolute',
          bottom: '115px',
          left: 0,
          right: 0,
        }}
      >
        <LowerBarContainer>
          {/* {showQRCode && <QRCode>
            <span>ติดตามผล</span>
            <span>เรียลไทม์</span>
            <img
              src='/static/images/qr-code-example.png'
              alt='qr-code'
              width='100'
              style={{marginTop: "4px"}}
            />
          </QRCode>} */}
          <TitleContainer>
            <BannerTitle
              style={{
                backgroundImage: `url("/static/images/lower-title.png")`,
                backgroundSize: 'cover',
              }}
            >
              <Logo>
                {/* <img src='/static/images/logo-2.png' alt='logo' width='76' /> */}
                <img src='/static/images/logo.png' alt='logo' width='120' />
              </Logo>
              <LowerbarDistrictItem district={resultsByAreaRefCode[areaRefCode]} />
            </BannerTitle>
          </TitleContainer>
          <Container>
            <ListWrapper>
              <LowerbarDistrictItemRight district={resultsByAreaRefCode[areaRefCode]} />
            </ListWrapper>
          </Container>
          <div
            style={{
              height: '155px',
              width: '398px',
              // backgroundColor: 'var(--white)',
              backgroundImage: `url("/static/images/lower-right-banner.png")`,
              backgroundSize: 'cover',
              zIndex: 3,
            }}
          ></div>
        </LowerBarContainer>
      </motion.div>
    </div>
  );
};
