import { motion } from "framer-motion";
import { Counter } from "../counter/Counter";
import {
  Container,
  NumbersContainer,
  NumberItem,
  SeatNumber,
  VoteNumber,
  ImageWrapper,
  OrderNumber,
} from "./CandidateCardSideBar.style";
import { Border } from "../../templates/sidebar/SideBar.style";

export const CandidateCardSideBar = (props) => {
  const {
    imgUrl,
    orderNumber,
    areaSeats,
    areaVotes,
    partylistSeats,
    partylistVotes,
    totalSeats,
    partyColor
  } = props;

  return (
    <Container>
      <Border color={partyColor}>
        <div>{orderNumber}</div>
      </Border>
      <ImageWrapper>
        <motion.img src={imgUrl} />
      </ImageWrapper>
      <NumbersContainer>
        <NumberItem>
          <SeatNumber>
            {areaSeats || "0"}
          </SeatNumber>
          <VoteNumber>
            <Counter from={0} to={areaVotes} />
          </VoteNumber>
        </NumberItem>
        <NumberItem>
          <SeatNumber>
            {partylistSeats || "0"}
          </SeatNumber>
          <VoteNumber>
            <Counter from={0} to={partylistVotes} />
          </VoteNumber>
        </NumberItem>
        <NumberItem>
          <SeatNumber>
            {totalSeats || "0"}
          </SeatNumber>
        </NumberItem>
      </NumbersContainer>
    </Container> 
  )
;}