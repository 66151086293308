import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { CandidateCardSideBar } from "../../atoms/candidateCardSideBar/CandidateCardSideBar";
import { SummaryScoreSideBar } from "../../atoms/summaryScoreSideBar/SummaryScoreSideBar";
import {
  containerSideBar,
  itemSideBarVariants,
} from "../../../utils/motion";
import { getCountProgress } from "../../../utils/overallResult";
import { parseParams } from "../../../utils/params";

import {
  SideBarContainer,
  BannerTop,
  BannerBottom,
  Logo,
  Container,
  ListWrapper,
  ListContainer,
  ListItem,
  ListHeader,
  TextContainer,
} from "../../templates/sidebar/SideBar.style";
import { groupItems } from "../../../utils/groupItems";
import { parties } from "../../../const/parties";
import { useSearchParams } from "react-router-dom";

export const SideBar10CandidatesPage = () => {
  const { hideProgress, t } = parseParams({ t: 9000, hideProgress: false });

  const [params] = useSearchParams();
  const lang = params.get('lang');

  const sizeItems = 10;
  const numberOfItemInGroup = 5;
  const [isActiveIndex, setIsActiveIndex] = useState(0);
  const [groupCandidates, setGroupCandidates] = useState([]);
  const fullInfoResults = useSelector((state) => state.fullInfoResultReducer.fullInfoResults);
  const summary = useSelector((state) => state.overallResultReducer.summary);

  useEffect(() => {
    if (fullInfoResults && fullInfoResults.length) {
      const tempCadidates = fullInfoResults.slice(0, sizeItems);
      setGroupCandidates(groupItems(tempCadidates, numberOfItemInGroup));
    }
  }, [fullInfoResults]);

  useEffect(() => {
    if (t <= 0) {
      return;
    }

    const timer = setInterval(() => {
      if (isActiveIndex >= (groupCandidates.length - 1)) {
        setIsActiveIndex(0)
      } else {
        setIsActiveIndex((index) => index + 1);
      }
    }, t);
    return () => {
      clearInterval(timer)
    };
  }, [groupCandidates.length, isActiveIndex, t]);

  return (
    <div className="page-container">
    <SideBarContainer>
      <BannerTop
        style={{
          backgroundImage: `url("/static/images/l-shape-title.png")`,
        }}>
        <SummaryScoreSideBar
          titleTextFirstLine={lang === 'en' ? `Top 10 Number Of Seats` : `10 อันดับจำนวนที่นั่ง`}
          titleTextSecondLine={lang === 'en' ? `Constituency + Party-list` : `ส.ส. เขต + บัญชีรายชื่อ`}
          subText={ (lang === 'en') ? 'Overall' : 'ทั่วประเทศ' }
          smallSecondLine={true}
        />
      </BannerTop>
      <ListHeader>
        <TextContainer>{ (lang === 'en') ? 'CON.' : 'เขต' }</TextContainer>
        <TextContainer>{ (lang === 'en') ? 'Party-list' : 'บช.รายชื่อ' }</TextContainer>
        <TextContainer>{ (lang === 'en') ? 'Total (seats)' : 'รวม (ที่นั่ง)' }</TextContainer>
      </ListHeader>
      <Container>
        <ListWrapper>
          {
            groupCandidates.map((group, idx) => (
              <motion.div
                variants={containerSideBar}
                animate={isActiveIndex === idx ? "show" : "hidden"}
                style={{width: '100%', height: '100%'}}
                key={`group-candidate-${idx}`}>
                <ListContainer>
                  {
                    group?.map((party, index) => {
                      return (
                        <ListItem key={`party-${party.partyRefCode}`}>
                          <motion.div
                            variants={itemSideBarVariants}
                            style={{
                              width: "100%",
                              height: "100%",
                              // backgroundColor: party.rank === 1 ? "var(--ciBlue)" : "var(--white)",
                              // color: party.rank === 1 ? "var(--white)" : "var(--ciBlue)",
                            }}
                          >
                          <CandidateCardSideBar
                            orderNumber={party.rank}
                            imgUrl={`${parties[party.partyRefCode]?.images.logoImageUrl}`}
                            areaSeats={party.areaResult.seats}
                            // areaVotes={party.areaResult.votes}
                            partylistSeats={party.partylistResult.seats}
                            // partylistVotes={party.partylistResult.votes}
                            totalSeats={party.totalResult.seats}
                            partyColor={parties[party.partyRefCode]?.color}
                          />
                          </motion.div>
                        </ListItem>
                      );
                    })
                  }
                </ListContainer>
              </motion.div>
            ))
          }
        </ListWrapper>
      </Container>
      {/* <BannerBottom
        style={{
          backgroundImage: `url("/static/images/l-shape-footer.png")`,
        }}>
        <Logo>
          <img
            src="/static/images/logo.png"
            alt="logo"
            width="200" />
        </Logo>
      </BannerBottom> */}
    </SideBarContainer>
    </div>
  )
}
