import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allAreaRefCodes: [],
  allAreaOptions: [],
  areasByRefCode: {},
};

export const areasSlice = createSlice({
  name: "area",
  initialState: initialState,
  reducers: {
    setAreasWithAreaGroupsByRegion: (state, action) => {
      const regionsByAreaRefCode = action.payload.areaGroupsByRegion.reduce((result, region) => {
        for (var areaRefCode of region.areaRefCodes) {
          result[areaRefCode] = region;
        }
        return result;
      }, {});

      const areasByRefCode = action.payload.areas.reduce((result, area) => {
        const region = regionsByAreaRefCode[area.refCode];
        result[area.refCode] = {
          ...area,
          regionRefCode: region.refCode,
          region: region,
        };
        return result;
      }, {});

      const allAreaRefCodes = action.payload.areas.map(area => area.refCode);
      const allAreaOptions = action.payload.areas.map(area => ({
        value: area.refCode,
        label: area.translations.th.fullName,
      }));

      return {
        allAreaRefCodes,
        allAreaOptions,
        areasByRefCode,
      };
    },
  },
});

export const { setAreasWithAreaGroupsByRegion } = areasSlice.actions;

export default areasSlice.reducer;
