import styled from 'styled-components';

export const LayoutFullPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-size: cover;
  background-repeat: no-repeat;

  width: 1920px;
  height: 1080px;

  backgroundImage: url('/static/images/graphic-bg.png'),
`;

