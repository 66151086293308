import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { parseParams } from '../../../utils/params';
import { getCountProgress } from '../../../utils/overallResult';
import { Counter } from '../../atoms/counter/Counter';
import { CandidateListFullPage } from '../../molecules/candidateListFullPage/CandidateListFullPage';

import {
  ContentContainer,
  Wrapper,
  Container,
  TitleWrapper,
  TitleContainer,
  Title,
  Subtitle,
  CandidateWrapper,
  Unit,
  RibbonWrapper,
} from './DistrictsResultFullPage.style';
import { useIntervalOrClick } from '../../../utils/effects';
import { orderBy } from 'lodash';

const container = {
  hidden: {
    transition: {
      duration: 1,
      staggerChildren: 0.02,
      staggerDirection: -1,
    },
  },
  show: {
    transition: {
      staggerChildren: 0.08,
    },
  },
};

export const DistrictsResultFullPageManual = ({ areaRefCode = '1001' }) => {
  const { t, maxItems } = parseParams({ t: 9000, maxItems: 50 });

  const [ params ] = useSearchParams();
  const lang = params.get('lang');

  const allAreaRefCodes = useSelector((state) => {
    return state.areaReducer.allAreaRefCodes;
  });
  const results = useSelector((state) => {
    return state.districtVoteResultReducer.districts;
  });
  const resultsByAreaRefCode = useSelector((state) => {
    return state.districtVoteResultReducer.resultsByAreaRefCode;
  });
  const isReady = useSelector((state) => {
    return allAreaRefCodes.length > 0
      && results.length > 0;
  });

  const [recentUpdatedAreaRefCodes, setRecentUpdatedAreaRefCodes] = useState([]);
  const [currentAreaRefCode, setCurrentAreaRefCode] = useState('8101');

  useIntervalOrClick({
    isReady,
    delay: t,
    acceptClick: true,
    callback: () => {
      console.log('Start');

      var clonedRecentUpdatedAreaRefCodes = [...recentUpdatedAreaRefCodes];
      console.log('Remaining items', clonedRecentUpdatedAreaRefCodes.length);

      // Try refill
      if (clonedRecentUpdatedAreaRefCodes.length <= 0) {
        console.log('Finding update...');
        const newRecentUpdated = orderBy(
          [...results],
          [
            (item) => item.sourceUpdatedBy,
            (item) => item.progress,
          ],
          [
            'desc',
            'desc',
          ],
        );
        clonedRecentUpdatedAreaRefCodes = newRecentUpdated.map(item => item.areaRefCode).slice(0, maxItems);
      }

      // Show the first
      if (clonedRecentUpdatedAreaRefCodes.length > 0) {
        const newAreaRefCode = clonedRecentUpdatedAreaRefCodes.shift();
        setRecentUpdatedAreaRefCodes(clonedRecentUpdatedAreaRefCodes);
        setCurrentAreaRefCode(newAreaRefCode);
      }
    },
  });

  if (!isReady) {
    return <></>;
  }

  return (
    <div className='page-container'>
      <Container
        style={{
          backgroundImage: `url('/static/images/graphic-bg.png')`,
        }}
      >
        <Wrapper>
          <ContentContainer>
            <TitleWrapper>
              <TitleContainer>
                {lang === 'en' ? (
                  <>
                    {/** เปลี่ยน Title กับ Subtitle */}
                    <Title>Constituency Votes</Title>
                    <Subtitle>(Unofficial)</Subtitle>
                  </>
                ) : (
                  <>
                    {/** Title ของหน้า e.g. ผลการนับคะแนน ส.ส. */}
                    {/* <Title>{overlayInfo.countingScore}</Title> */}
                    <Title>ผลคะแนน ส.ส. เขต</Title>

                    {/** Subtext ที่วงเล็บข้างหลัง title ของหน้า e.g. (อย่างไม่เป็นทางการ) */}
                    {/* <Subtitle>{overlayInfo.unofficial}</Subtitle> */}
                    <Subtitle>(อย่างไม่เป็นทางการ)</Subtitle>
                  </>
                )}
              </TitleContainer>
            </TitleWrapper>
            <RibbonWrapper
              style={{
                backgroundImage: `url('/static/images/h-ribbon.png')`,
              }}
            >
              <motion.div
                variants={container}
                initial='hidden'
                animate='show'
                exit='hidden'
                style={{
                  color: 'var(--ciWhite)',
                  fontSize: '46px',
                  fontWeight: '700',
                  width: '1255px',
                }}
              >
                <div
                  style={{
                    overflow: 'hidden',
                    display: 'flex',
                    height: '100%',
                    alignItems: 'center',
                  }}
                >
                  {lang === 'en'
                    ? `${resultsByAreaRefCode[currentAreaRefCode]?.area?.translations?.en?.fullName}`
                    : `${resultsByAreaRefCode[currentAreaRefCode]?.area?.translations?.th?.fullName}`}
                </div>
              </motion.div>
              <motion.div
                variants={container}
                initial='hidden'
                animate='show'
                exit='hidden'
                style={{
                  color: 'var(--ciWhite)',
                  fontSize: '34px',
                  fontWeight: '700',
                }}
              >
                {
                  resultsByAreaRefCode[currentAreaRefCode]?.dataSource === 'ect'
                  ? (<div
                      style={{
                        overflow: 'hidden',
                        display: 'flex',
                        alignItems: 'baseline',
                        marginTop: '2px',
                      }}
                    >
                      {lang === 'en' ? 'Counted' : 'นับแล้ว'}
                      <div
                        style={{
                          fontFamily: 'Avenir LT Std',
                          marginLeft: '16px',
                          fontSize: '48px',
                          fontWeight: '600',
                        }}
                      >
                        {/** แก้ค่าของ to={...} */}
                        <Counter from={0} to={resultsByAreaRefCode[currentAreaRefCode]?.progress ?? 0} digit={1} />
                        <Unit>%</Unit>
                      </div>
                    </div>)
                    : null
                  }
              </motion.div>
            </RibbonWrapper>
            <CandidateWrapper>
              <CandidateListFullPage
                candidateList={resultsByAreaRefCode[currentAreaRefCode]?.leadingCandidateResults}
              />
            </CandidateWrapper>
          </ContentContainer>
        </Wrapper>
      </Container>
    </div>
  );
};
