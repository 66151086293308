import { Counter } from "../counter/Counter";
import {
  Container,
  TitleContainer,
  Title,
  Subtitle,
  Score,
  ImageWrapper,
  TitleTextWrapper,
} from "./CandidateCard.style";

export const CandidateCard = (props) => {
  const { firstName, lastName, imgUrl, score, bgColor, logoUrl } = props;
  
  return (
    <Container>
      <ImageWrapper 
        style={{
          backgroundImage: `url(${imgUrl})`,
        }}
        bgColor={bgColor}
      />
      <TitleContainer bgColor={bgColor}>
        <TitleTextWrapper>
          <img
            src={logoUrl}
            alt="logo"
            width="65"
            height="65"
          />
          <div style={{width: "70%"}}>
            <Title>{firstName}</Title>
            <Subtitle>{lastName}</Subtitle>
          </div>
        </TitleTextWrapper>
        <Score bgColor={bgColor}>
          <Counter from={0} to={score} />
        </Score>
      </TitleContainer>
    </Container> 
  )
;}