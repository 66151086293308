import React, { useEffect, useMemo, useRef } from 'react'
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'
import qs from 'qs';
// import { TemplateFullPage } from "./components/templates/templateFullPage/TemplateFullPage";
// import { FullPageLayoutA } from "./components/templates/fullPageLayoutA/FullPageLayoutA";
// import { FullPageLayoutB } from "./components/templates/fullPageLayoutB/FullPageLayoutB";
// import { FullPageLayoutC } from "./components/templates/fullPageLayoutC/FullPageLayoutC";
// import { FullPageLayoutD } from "./components/templates/fullPageLayoutD/FullPageLayoutD";

import { DashBoardPage } from "./components/pages/dashBoardPage/DashBoardPage";
import { LowerBarDistrictsPage } from "./components/pages/lowerBarDistrictsPage/LowerBarDistrictsPage";
import { LowerBarOverallPage } from "./components/pages/lowerBarOverallPage/LowerBarOverallPage";
import { SideBar10CandidatesPage } from "./components/pages/sideBar10CadidatesPage/SideBar10CandidatesPage";
import { SideBar5CandidatesPage } from "./components/pages/sideBar5CandidatesPage/SideBar5CandidatesPage";
import { OverallFullPageLayoutA } from "./components/pages/overallFullPageLayoutA/OverallFullPageLayoutA";
import { OverallFullPageLayoutB } from "./components/pages/overallFullPageLayoutB/OverallFullPageLayoutB";
import { OverallFullPageLayoutC } from "./components/pages/overallFullPageLayoutC/OverallFullPageLayoutC";
import { DistrictsResultFullPage } from "./components/pages/districsResultFullPage/DistrictsResultFullPage";

import { useSelector, useDispatch } from 'react-redux';
import { APIJSON, APIRESULTJSON, APIRESULTJSON66 } from './services/axios';
import { setCandidates } from './store/candidate/reducers';
import { setDistricts } from './store/district/reducers';
import { setParties } from './store/party/reducers';
import { setOverallResults } from './store/overAllResult/reducers';
import { setCurrentLanguage } from './store/language/reducers';
import { setMiscellaneouseInfo } from './store/miscellaneousInfo/reducers';
import { setCandidateVoteResults } from './store/candidateVoteResult/reducers';
import { setContext } from './store/context/reducers';
import { setPullingTime, setPullingMode } from './store/pullingTime/reducers';

import "./App.css";
import { setFullInfoResults, setFullInfoResultsByDistrict } from './store/fullInfoResult/reducers';
import { setDistrictsVoteResult } from './store/districtVoteResult/reducers';
import { SideBar5ItemsWithNamePage } from './components/pages/sideBar5ItemsWithNamePage/SideBar5ItemsWithNamePage';
import { OverallFullPageLayoutD } from './components/pages/overallFullPageLayoutD/OverallFullPageLayoutD';
import { OverallFullPageLayoutE } from './components/pages/overallFullPageLayoutE/OverallFullPageLayoutE';
import { OverallFullPageLayoutF } from './components/pages/overallFullPageLayoutF/OverallFullPageLayoutF';
import { SideBar5ItemsWithNamePageByProvince } from './components/pages/sideBar5ItemsWithNamePage/SideBar5ItemsWithNamePageByProvince';
import { SideBar5ItemsWithNamePagePartyList } from './components/pages/sideBar5ItemsWithNamePage/SideBar5ItemsWithNamePagePartyList';
import { setAreasWithAreaGroupsByRegion } from './store/area/reducers';
import { LowerBar50ItemsPage } from './components/pages/lowerBar50ItemsPage/LowerBar50ItemsPage';
import { LowerBarRegionsPage } from './components/pages/lowerBarRegionsPage/LowerBarRegionsPage';
import { StudioPage } from './components/pages/studio/StudioPage';
import { StagePage } from './components/pages/stagePage/StagePage';
import { setSummariesByProvinceRefCode } from './store/summariesByProvince/reducers';
import { setProvinces } from './store/province/reducers';
import { setRegions } from './store/region/reducers';
import { setRegionVoteResult } from './store/regionVoteResult/reducers';
import { LowerBarDistrictsNoZeroPage } from './components/pages/lowerBarDistrictsPage/LowerBarDistrictsNoZeroPage';
import { DistrictsResultFullPageManual } from './components/pages/districsResultFullPage/DistrictsResultFullPageManual';

export default function App() {
  const queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const oldLang = useRef(undefined);
  const currentLang = useMemo(() => queryString.lang ? queryString.lang.toUpperCase() : 'TH', [queryString.lang]);
  const districtInformation = useSelector((state) => state.districtReducer.districts);
  const districtVoteResultData = useSelector((state) => state.districtVoteResultReducer.districts);
  const candidateVoteResult = useSelector((state) => state.candidateVoteResultReducer.candidateVoteResults);
  const candidateInformation = useSelector((state) => state.candidateReducer.candidates);
  const partiesInformation = useSelector((state) => state.partyReducer.parties);
  const context = useSelector((state) => state.contextReducer);
  const pullingTimeState = useSelector(
    (state) => state.pullingTimeReducer.pullingTime
  );
  const pullingModeState = useSelector(
    (state) => state.pullingTimeReducer.mode
  );
  const dispatch = useDispatch();

  const shallowEqual = (object1, object2) => {
    let checked = true;
    if(object1 && object2){
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      checked = false;
    }
    keys1.forEach((key) => {
      if (object1[key] !== object2[key]) {
        checked = false;
      }
    });
  } else {
    checked = object1 === object2
  }
    return checked;
  };

  useEffect(() => {
    const fetchContext = setInterval(() => {
      APIJSON.get("/context.json").then((res) => {
        const { pullingTime, mode, ...context } = res.data;
        dispatch(setContext({ ...context }));

        if (!shallowEqual(pullingTime, pullingTimeState)) {
          dispatch(setPullingTime({ pullingTime: pullingTime }));
        }
        if (!shallowEqual(mode, pullingModeState)) {
          dispatch(setPullingMode(mode));
        }
      });
    }, context.metadataPullingTime * 1000);

    return function cleanup() {
      clearInterval(fetchContext);
    };
  }, [context]);

  useEffect(() => {
    const fetchAreas = async () => {
      const [areaResponse, areaGroupsByRegionResponse] = await Promise.all([
        APIRESULTJSON66.get(`/master/areas.json`),
        APIRESULTJSON66.get(`/master/area-groups-by-region.json`),
      ]);

      dispatch(
        setAreasWithAreaGroupsByRegion({
          areas: areaResponse.data.data,
          areaGroupsByRegion: areaGroupsByRegionResponse.data.data,
        }),
      );
    }
    fetchAreas();
  }, []);

  useEffect(() => {
    const fetchProvinces = async () => {
      const response = await APIRESULTJSON66.get(`/master/provinces.json`);
      dispatch(setProvinces({ provinces: response.data.data }));
    }
    fetchProvinces();
  }, []);

  useEffect(() => {
    const fetchRegions = async () => {
      const response = await APIRESULTJSON66.get(`/master/area-groups-by-region.json`);
      dispatch(setRegions({ regions: response.data.data }));
    }
    fetchRegions();
  }, []);

  useEffect(() => {
    APIRESULTJSON66.get(`/reports/seats-by-party.json`).then((res) => {
      dispatch(
        setCandidateVoteResults({
          candidateVoteResults: res.data.data,
        })
      );
      dispatch(
        setOverallResults({ summary: res.data.summaryVote }),
      );
    });

    APIRESULTJSON66.get(`/reports/leading-candidates-by-area.json`).then((res) => {
      dispatch(setDistrictsVoteResult({ districts: res.data.data }));
    });

    APIRESULTJSON66.get(`/reports/summary-by-province.json`).then((res) => {
      dispatch(
        setSummariesByProvinceRefCode({ summariesByProvince: res.data.data })
      );
    });
    APIRESULTJSON66.get(`/reports/summary-by-region.json`).then((res) => {
      dispatch(
        setRegionVoteResult({ regionVoteList: res.data?.data })
      );
    });

    APIRESULTJSON66.get(`/reports/summary-by-region.json`).then((res) => {
      dispatch(
        setRegionVoteResult({ regionVoteList: res.data?.data })
      );
    });

    APIJSON.get("/context.json").then((res) => {
      const { pullingTime, mode, ...context } = res.data;
      dispatch(setContext({ ...context }));
      dispatch(setPullingTime({ pullingTime: pullingTime }));
      dispatch(setPullingMode(mode))
    });
  }, [])

  useEffect(() => {
    if (pullingModeState !== 'pooling') {
      return;
    }
    if (!pullingTimeState || !pullingTimeState.overallResultInterval) {
      return;
    }
    const fetchInterval = setInterval(async () => {
      APIRESULTJSON66.get(`/reports/seats-by-party.json`).then((res) => {
        dispatch(
          setOverallResults({ summary: res.data.summaryVote }),
        );
        dispatch(
          setCandidateVoteResults({
            candidateVoteResults: res.data.data,
          })
        );
      });
      APIRESULTJSON66.get(`/reports/leading-candidates-by-area.json`).then((res) => {
        dispatch(setDistrictsVoteResult({ districts: res.data.data }));
      });
      APIRESULTJSON66.get(`/reports/summary-by-province.json`).then((res) => {
        dispatch(
          setSummariesByProvinceRefCode({ summariesByProvince: res.data.data })
        );
      });
      APIRESULTJSON66.get(`/reports/summary-by-region.json`).then((res) => {
        dispatch(
          setRegionVoteResult({ regionVoteList: res.data?.data })
        );
      });
    }, pullingTimeState.overallResultInterval * 1000);
    return function cleanup() {
      clearInterval(fetchInterval);
    };
  }, [pullingTimeState, pullingModeState]);

  useEffect(() => {
    if (oldLang.current !== currentLang) {
      APIJSON.get(`/candidates${currentLang}.json`).then((res) => {
        dispatch(setCandidates({ candidates: res.data }));
      });

      APIJSON.get(`/districts${currentLang}.json`).then((res) => {
        dispatch(setDistricts({ districts: res.data }));
      });

      APIJSON.get(`/parties${currentLang}.json`).then((res) => {
        dispatch(setParties({ parties: res.data }));
      });

      dispatch(setCurrentLanguage({ currentLanguage: currentLang }));

      APIJSON.get(`/miscellaneous${currentLang}.json`).then((res) => {
        dispatch(setMiscellaneouseInfo(res.data));
      });
      oldLang.current = currentLang
    }
  }, [currentLang]);

  useEffect(() => {
    dispatch(setFullInfoResults(candidateVoteResult));
  }, [candidateVoteResult]);

  useEffect(() => {
    if (districtVoteResultData?.length && Object.keys(candidateInformation).length && Object.keys(districtInformation).length) {
      const mergedData = [];
      districtVoteResultData.forEach((eachDistrict) => {
        const selectedDistrict = districtInformation[eachDistrict.number];
        if (selectedDistrict) {
          const returnData = {
            ...eachDistrict,
            districtName: selectedDistrict.name,
          };
          mergedData.push(returnData);
        }
      });
      if (mergedData.length > 0) {
        mergedData.sort((itemA, itemB) => {
          if (itemA.districtName > itemB.districtName) {
            return 1;
          }
          if (itemA.districtName < itemB.districtName) {
            return -1;
          }
          return 0;
        });
        dispatch(setFullInfoResultsByDistrict(mergedData));
      }
    }
  }, [districtVoteResultData, candidateInformation, districtInformation]);


  return (
    <Router basename="/">
      <Routes>
        {/** Dashboard */}
        <Route path="/" element={<DashBoardPage />} />
        <Route path="/studio/:stageCode" element={<StudioPage />} />
        <Route path="/stage/:stageCode" element={<StagePage previewMode={false} />} />
        <Route path="/stage/:stageCode/frames/preview" element={<StagePage previewMode={true} source="preview" />} />
        <Route path="/stage/:stageCode/frames/live" element={<StagePage previewMode={true} source="live" />} />
        <Route path="/l-shape"
          element={
            <div className="page-container">
              <SideBar10CandidatesPage />
              <LowerBarDistrictsPage />
              <div
                className="img-bg-lowerbar"
                style={{
                  backgroundImage: `url("/static/images/img-bg-lowerbar.png")`,
                }}
              />
            </div>
          }/>
        {/** L-shape (ทั่วประเทศ): Side 5 พรรคแรก (ส.ส. ทั่วประเทศ) + Lower แบ่งเขต */}
        <Route path="/l-shape/1"
          element={
            <div className="page-container">
              <SideBar5CandidatesPage />
              <LowerBarDistrictsPage />
              <div
                className="img-bg-lowerbar"
                style={{
                  backgroundImage: `url("/static/images/img-bg-lowerbar.png")`,
                }}
              />
            </div>
          }/>
        {/** L-shape (ตามภาค): Side 5 พรรคแรก (ส.ส. เขต ตามภาค) + Lower แบ่งเขต */}
        <Route
          path="/l-shape/2"
          element={
            <div className="page-container">
              <SideBar5ItemsWithNamePage />
              <LowerBarDistrictsPage />
              <div
                className="img-bg-lowerbar"
                style={{
                  backgroundImage: `url("/static/images/img-bg-lowerbar.png")`,
                }}
              />
            </div>
          }/>
        {/** L-shape (ตามจังหวัด): Side 5 พรรคแรก (ส.ส. เขต ตามจังหวัด) + Lower แบ่งเขต */}
        <Route
          path="/l-shape/3"
          element={
            <div className="page-container">
              <SideBar5ItemsWithNamePageByProvince />
              <LowerBarDistrictsPage />
              <div
                className="img-bg-lowerbar"
                style={{
                  backgroundImage: `url("/static/images/img-bg-lowerbar.png")`,
                }}
              />
            </div>
          }/>
        {/** L-shape (บัญชีรายชื่อ): Side 5 พรรคแรก (ส.ส. บัญชีรายชื่อ) + Lower แบ่งเขต */}
        <Route
          path="/l-shape/4"
          element={
            <div className="page-container">
              <SideBar5ItemsWithNamePagePartyList />
              <LowerBarDistrictsPage />
              <div
                className="img-bg-lowerbar"
                style={{
                  backgroundImage: `url("/static/images/img-bg-lowerbar.png")`,
                }}
              />
            </div>
          }/>

        {/** Sidebar */}
        {/** Sidebar (10 พรรคแรก): ส.ส. ทั่วประเทศ */}
        <Route path="/sidebar/1" element={<SideBar10CandidatesPage />}/>
        {/** Sidebar (5 พรรคแรก): ส.ส. ทั่วประเทศ */}
        <Route path="/sidebar/2" element={<SideBar5CandidatesPage />} />
        {/** Sidebar (5 พรรคแรก): ส.ส. เขต ตามภาค + มีช่ือพรรค */}
        <Route path="/sidebar/3" element={<SideBar5ItemsWithNamePage />} />
        {/** Sidebar (5 พรรคแรก): ส.ส. เขต ตามจังหวัด + มีช่ือพรรค */}
        <Route path="/sidebar/4" element={<SideBar5ItemsWithNamePageByProvince />} />
        {/** Sidebar (5 พรรคแรก): ส.ส. บัญชีรายชื่อ + มีช่ือพรรค */}
        <Route path="/sidebar/5" element={<SideBar5ItemsWithNamePagePartyList />} />

        {/** Lower bar */}
        {/** Lower bar (3 อันดับแรก): ทุกเขตที่คะแนน >0 */}
        <Route path="/lowerbar/area/0" element={<LowerBarDistrictsNoZeroPage />}/>
        {/** Lower bar (3 อันดับแรก): ส.ส. เขต 50 เขต อัปเดตล่าสุด */}
        <Route path="/lowerbar/area/1" element={<LowerBar50ItemsPage />}/>
        {/** Lower bar (3 อันดับแรก): ส.ส. เขต ตามภาค */}
        <Route path="/lowerbar/area/2" element={<LowerBarRegionsPage />}/>
        {/** Lower bar (3 อันดับแรก): ส.ส. เขต ตามจังหวัด */}
        <Route path="/lowerbar/area/3/:areaRefCode" element={ <LowerBarDistrictsPage />}/>
        {/* <Route path="/lowerbar/overall" element={<LowerBarOverallPage />}/> */}

        {/** Full page (Overall) */}
        {/* <Route path="/fullpage/overall/1" element={<div className="page-container"><OverallFullPageLayoutA /></div>}/> */}
        {/** Full page (Overall): จำนวน ส.ส. เขต รายภาค */}
        <Route path="/fullpage/overall/2" element={<OverallFullPageLayoutB />}/>
        {/** Full page (Overall): จำนวน ส.ส. เขต ทั่วประเทศ */}
        <Route path="/fullpage/overall/3" element={<OverallFullPageLayoutC />}/>
        {/** Full page (Overall): จำนวน ส.ส. ทั่วประเทศ (เขต + บัญชีรายชื่อ) */}
        <Route path="/fullpage/overall/4" element={<OverallFullPageLayoutD />}/>
        {/** Full page (Overall): คะแนน ส.ส. ระบบบัญชีรายชื่อ */}
        <Route path="/fullpage/overall/5" element={<OverallFullPageLayoutE />}/>
        {/** Full page (Overall): จำนวน + คะแนน ส.ส. เขต รายจังหวัด */}
        <Route path="/fullpage/overall/6" element={<OverallFullPageLayoutF />}/>

        {/** Full page (Area) */}
        {/** Full page (Area): ส.ส. เขต 3 อันดับแรก รายเขต */}
        <Route path="/fullpage/area" element={<DistrictsResultFullPage />}/>
        {/** Full page (Area): ส.ส. เขต 3 อันดับแรก รายเขต แบบเจาะจงเขต */}
        <Route path="/fullpage/area/:areaRefCode" element={<DistrictsResultFullPage />}/>
        <Route path="/fullpage/area-auto/:areaRefCode" element={<DistrictsResultFullPageManual />}/>
      </Routes>
    </Router>
  );
};
