import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
  gap: 8px;
  padding-right: 24px;
  padding-left: 56px;
  height: 100%;
  width: 479px;
  overflow: hidden;
`;

export const OrderNumber = styled.div`
  font-family: 'Avenir LT Std';
  /* font-size: 50px; */
  font-size: 44px;
  font-weight: 900;

  min-width: 70px;
  width: 70px;

  text-align: center;
  align-self: center;
`;

export const TextContainer = styled.div`
  width: 100%;
  padding-left: 8px;
  overflow: hidden;

  font-family: 'sukhumvitset';
  flex: 1 1 auto;
`;

export const PartyName = styled.div`
  font-family: 'sukhumvitset';
  font-size: 27px;
  font-weight: 700;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  width: 100%;
  /* padding-left: 8px; */
`;

export const NumbersContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
`;

export const NumberItem = styled.div`
  /* margin-right: 32px; */
  padding-top: 8px;
  flex: 1;
  min-width: 80px;
  width: 80px;
  height: 100%;

  display: flex;
  
  flex-direction: column;
  text-align: right;
`;

export const SeatNumber = styled.div`
  font-family: 'Avenir LT Std';
  /* font-size: 40px; */
  font-size: 44px;
  font-weight: 900;
  line-height: 1.2;
`;

export const VoteNumber = styled.div`
  font-family: 'Avenir LT Std';
  font-size: 21px;
  font-weight: 600;
  line-height: 1.2;
  /* padding-right: 4px; */
  display: flex;
  align-items: baseline;
  gap: 4px;
`;

export const Unit = styled.div`
  font-family: 'sukhumvitset';
`;

export const ImageWrapper = styled.div`
  /* position: absolute; */
  /* bottom: 0; */
  /* left: 0; */
  min-width: 70px;
  width: 70px;
  height: 70px;
  overflow: hidden;
  background-color: var(--white);
  border-radius: 6px;
  flex: 0 0 auto;

  display: flex;
  justify-content: center;

  > img {
    max-height: 100%;
    margin-top: auto;
  }
`;
