import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const ListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px 24px;
  flex: 1;
  height: 24px;
`

export const ListItem = styled.div`
  margin-bottom: 8px;
  position: relative;
  width: 100%;
  height: 72px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
`

export const Title = styled.div`
  font-family: 'sukhumvitset';
  font-size: 28px;
  font-weight: 700;
  line-height: 1.8;
  color: var(--grey900);
`;

export const Orange = styled.span`
  color: var(--ciOrange);
`
