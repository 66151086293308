import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { parseParams } from '../../../utils/params';
import { getCountProgress } from '../../../utils/overallResult';
import { Counter } from '../../atoms/counter/Counter';
import { CandidateListFullPage } from '../../molecules/candidateListFullPage/CandidateListFullPage';

import {
  ContentContainer,
  Wrapper,
  Container,
  TitleWrapper,
  TitleContainer,
  Title,
  Subtitle,
  CandidateWrapper,
  Unit,
  RibbonWrapper,
} from './DistrictsResultFullPage.style';

const container = {
  hidden: {
    transition: {
      duration: 1,
      staggerChildren: 0.02,
      staggerDirection: -1,
    },
  },
  show: {
    transition: {
      staggerChildren: 0.08,
    },
  },
};

export const DistrictsResultFullPage = ({ areaRefCode = '1001' }) => {
  const overlayInfo = useSelector((state) => state.miscellaneousReducer.overlay);
  const districtInformation = useSelector((state) => state.districtReducer.districts);
  const fullInfoResultsByDristrict = useSelector((state) => {
    return state.districtVoteResultReducer.districts;
  });
  const resultsByAreaRefCode = useSelector((state) => {
    return state.districtVoteResultReducer.resultsByAreaRefCode;
  });

  const [params] = useSearchParams();
  const lang = params.get('lang');

  const ref = useRef();

  // Legacy
  const { t } = parseParams({ t: 9000 });
  const { id } = useParams();

  const {areaRefCode: areaRefCodeParams} = useParams();
  const [currentResult, setCurrentResult] = useState({});

  useEffect(() => {
    let activeAreaRefCode = areaRefCodeParams ?? areaRefCode
    setCurrentResult(resultsByAreaRefCode[activeAreaRefCode]);
  }, [areaRefCode, areaRefCodeParams, resultsByAreaRefCode]);

  // Legacy
  const [isActiveIndex, setIsActiveIndex] = useState(0);
  useEffect(() => {
    if (t <= 0) {
      return;
    }
    if (!id && fullInfoResultsByDristrict.length) {
      if (!ref.current && false) {
        const timer = setInterval(() => {
          if (isActiveIndex >= fullInfoResultsByDristrict.length - 1) {
            setIsActiveIndex(0);
          } else {
            setIsActiveIndex((index) => index + 1);
          }
        }, t);
        ref.current = timer;
      }
    }
  }, [fullInfoResultsByDristrict, isActiveIndex, t, id]);

  return (
    <div className='page-container'>
      <Container
        style={{
          backgroundImage: `url('/static/images/graphic-bg.png')`,
        }}
      >
        <Wrapper>
          <ContentContainer>
            <TitleWrapper>
              <TitleContainer>
                {lang === 'en' ? (
                  <>
                    {/** เปลี่ยน Title กับ Subtitle */}
                    <Title>Constituency Votes</Title>
                    <Subtitle>(Unofficial)</Subtitle>
                  </>
                ) : (
                  <>
                    {/** Title ของหน้า e.g. ผลการนับคะแนน ส.ส. */}
                    {/* <Title>{overlayInfo.countingScore}</Title> */}
                    <Title>ผลคะแนน ส.ส. เขต</Title>

                    {/** Subtext ที่วงเล็บข้างหลัง title ของหน้า e.g. (อย่างไม่เป็นทางการ) */}
                    {/* <Subtitle>{overlayInfo.unofficial}</Subtitle> */}
                    <Subtitle>(อย่างไม่เป็นทางการ)</Subtitle>
                  </>
                )}
              </TitleContainer>
            </TitleWrapper>
            <RibbonWrapper
              style={{
                backgroundImage: `url('/static/images/h-ribbon.png')`,
              }}
            >
              <motion.div
                variants={container}
                initial='hidden'
                animate='show'
                exit='hidden'
                style={{
                  color: 'var(--ciWhite)',
                  fontSize: '46px',
                  fontWeight: '700',
                  width: '1255px',
                }}
              >
                <div
                  style={{
                    overflow: 'hidden',
                    display: 'flex',
                    height: '100%',
                    alignItems: 'center',
                  }}
                >
                  {lang === 'en'
                    ? `${currentResult?.area?.translations?.en?.fullName}`
                    : `${currentResult?.area?.translations?.th?.fullName}`}
                </div>
              </motion.div>
              <motion.div
                variants={container}
                initial='hidden'
                animate='show'
                exit='hidden'
                style={{
                  color: 'var(--ciWhite)',
                  fontSize: '34px',
                  fontWeight: '700',
                }}
              >
                <div
                  style={{
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'baseline',
                    marginTop: '2px',
                  }}
                >
                  {lang === 'en' ? 'Counted' : 'นับแล้ว'}
                  <div
                    style={{
                      fontFamily: 'Avenir LT Std',
                      marginLeft: '16px',
                      fontSize: '48px',
                      fontWeight: '600',
                    }}
                  >
                    {/** แก้ค่าของ to={...} */}
                    <Counter from={0} to={currentResult?.progress ?? 0} digit={1} />
                    <Unit>%</Unit>
                  </div>
                </div>
              </motion.div>
            </RibbonWrapper>
            <CandidateWrapper>
              <CandidateListFullPage
                candidateList={currentResult?.leadingCandidateResults}
              />
            </CandidateWrapper>
          </ContentContainer>
        </Wrapper>
      </Container>
    </div>
  );
};
