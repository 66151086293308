import axios from "axios"

let BASE_API_URL = 'https://bkkelection65-data.st-th-1.byteark.com';
let API_FOLDER = 'fake';

let RESULT_API_URL_66 = 'https://election66-data.thaipbs.or.th';
let API_FOLDER_66 = 'election-result';

if (window && window.publicRuntimeConfig) {
  const config = window.publicRuntimeConfig;
  if (config.BASE_API_URL && config.BASE_API_URL !== '{{BASE_API_URL}}') {
    BASE_API_URL = config.BASE_API_URL;
  }
  if (config.API_FOLDER && config.API_FOLDER !== '{{API_FOLDER}}') {
    API_FOLDER = config.API_FOLDER;
  }
}

export const APIJSON = axios.create({
  baseURL: `${BASE_API_URL}/tv`,
  headers : {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
   }
});

export const APIRESULTJSON = axios.create({
  baseURL: `${BASE_API_URL}/${API_FOLDER}`,
  headers : {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
   }
});

export const APIRESULTJSON66 = axios.create({
  baseURL: `${RESULT_API_URL_66}/${API_FOLDER_66}`,
  headers : {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
   }
});
