import { createSlice } from "@reduxjs/toolkit";
import { sortBy, keyBy } from "lodash";

const initialState = {
  regionVoteList: [],
  resultsByRegionRefCode: {},
};

export const regionVoteSlice = createSlice({
  name: "regionVoteResult",
  initialState: initialState,
  reducers: {
    setRegionVoteResult: (state, action) => {
      let regionVoteList = [...action.payload.regionVoteList];
      state.regionVoteList = regionVoteList;
      state.resultsByRegionRefCode = keyBy(regionVoteList, region => region.regionRefCode)
    },
}});

export const { setRegionVoteResult } = regionVoteSlice.actions;

export default regionVoteSlice.reducer;
