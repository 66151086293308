import { createSlice } from "@reduxjs/toolkit";
import { keyBy, orderBy } from "lodash";

const sceneTemplates = [];

const initialState = {
  summariesByProvinceRefCode: {},
};

export const summariesByProvinceSlice = createSlice({
  name: "sceneTemplate",
  initialState: initialState,
  reducers: {
    setSummariesByProvinceRefCode: (state, action) => {
      const sortedSummaries = action.payload.summariesByProvince.map(summary => ({
        provinceRefCode: summary.provinceRefCode,
        allCandidateResults: orderBy(
          summary.allCandidateResults,
          [
            (item) => item.areaResultSeats,
            (item) => item.areaResultVotes,
            (item) => Number.parseInt(item.partyRefCode),
          ],
          [
            'desc',
            'desc',
            'asc',
          ],
        ),
      }))
      return {
        summariesByProvinceRefCode: keyBy(sortedSummaries, item => item.provinceRefCode),
      };
    },
  },
});

export const {
  setSummariesByProvinceRefCode
} = summariesByProvinceSlice.actions;

export default summariesByProvinceSlice.reducer;
