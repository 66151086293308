import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-size: cover;
  background-repeat: no-repeat;

  width: 1920px;
  height: 1080px;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 1620px;
  height: 930px;
  background: rgba(255 255 255 / 80%);
`;

export const TitleWrapper = styled.div`
  width: 100%;
  padding: 32px 116px 24px;
  background-color: var(--ciWhite);
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;
  padding-right: 8px;
`;

export const Title = styled.span`
  font-size: 80px;
  font-weight: 700;
  line-height: 1;
  color: var(--ciBlue);

  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 100%;
`;

export const Subtitle = styled.span`
  font-size: 40px;
  font-weight: 700;
  line-height: 1.4;
  color: var(--ciOrange);
`;

export const RibbonWrapper = styled.div`
  display: flex;
  gap: 62px;
  text-align: left;
  padding-left: 116px;

  background-position: right;
  background-repeat: no-repeat;

  width: 100%;
  height: 72px;
`;

export const DescriptionContainer = styled.div`
	overflow: hidden;
  display: flex;
  height: 100%;
  align-items: center;
  min-height: 72px;
`;

export const CountedTextContainer = styled.div`
	overflow: hidden;
  display: flex;
  align-items: baseline;
  margin-top: 2px;
`;

export const CountedValue = styled.div`
	font-family: 'Avenir LT Std';
  margin-left: 16px;
  font-size: 48px;
  font-weight: 600;
`;

export const Unit = styled.span`
  font-size: 80%;
  margin-left: 4px;
`;
