import { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import { motion } from "framer-motion";
import { filter, orderBy } from "lodash";

import { groupItems } from "../../../utils/groupItems";
import { parseParams } from "../../../utils/params";
import { useIntervalOrClick } from "../../../utils/effects";
import { PartyCardFullPageCustom } from "../../atoms/partyCardFullPageCustom/PartyCardFullPageCustom"
import { parties } from "../../../const/parties";

import {
  Container,
  ListContainer,
  ListItem,
} from "./PartyListFullPage.style"

const container = {
  hidden: {
    opacity: 0,
    transition: {
      staggerChildren: 0.02,
      staggerDirection: -1
    }
  },
  show: {
    opacity: 1,
    visibility: "visible",
    transition: {
      duration: 1,
      staggerChildren: 0.1,
      delayChildren: 0.6
    }
  },
  exit: {
    display: "none",
    transition: {
      duration: 1,
      staggerChildren: 0.1,
      delayChildren: 0.6
    }
  }
};

const itemVariants = {
  hidden: {
    opacity: 0,
    y: 80,
    transition: { type: "tween", duration: 2 }
  },
  show: {
    opacity: 1,
    y: 0,
    transition: { type: "tween", duration: 0.6 }
  }
};

export const PartyListFullPageForLayoutE = ({ sizeItems = 15, partyList = [] }) => {
  const { t } = parseParams({ t: -1 });

  const [isActiveIndex, setIsActiveIndex] = useState(0);
  const [groupCandidates, setGroupCandidates] = useState([]);
  const [isReady, setIsReady] = useState(false);

  const [ params ] = useSearchParams();
  const lang = params.get('lang');

  useEffect(() => {
    if (partyList && partyList.length) {
      // const tempCadidates = partyList.slice(0, sizeItems);
      const filteredEntries = orderBy(
        filter(partyList, entry => entry.partylistResult.votes > 0),
        entry => entry.partylistResult.votes,
        'desc',
      );
      setGroupCandidates(groupItems(filteredEntries, 8));
      setIsReady(true);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyList]);

  useIntervalOrClick({
    isReady,
    delay: t,
    acceptClick: true,
    callback: () => {
      if (isActiveIndex >= (groupCandidates.length - 1)) {
        setIsActiveIndex(0)
      } else {
        setIsActiveIndex((index) => index + 1);
      }
    }
  });

  return (
    <Container>
      {
        groupCandidates.map((group, idx) => (
          <motion.div
            variants={container}
            initial="hidden"
            animate={isActiveIndex === idx ? "show" : "exit"}
            style={{width: '100%', height: '100%'}}
            key={`page-${idx}`}>
            <ListContainer>
              {
                group?.map((candidate) => {
                  return (
                    <ListItem
                      key={`party-${candidate.partyRefCode}`}
                      className="party-item-container"
                      style={{
                        backgroundImage: `url('/static/images/h-list-bg.png')`,
                      }}
                    >
                      <motion.div
                        variants={itemVariants}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      >
                      <PartyCardFullPageCustom
                        imgUrl={`${parties[candidate.partyRefCode]?.images.logoImageUrl}`}
                        partyName={`${
                          lang === 'en'
                          ? parties[candidate.partyRefCode]?.translations?.en?.name
                          : parties[candidate.partyRefCode]?.translations?.th?.name
                        }`}
                        column1Value={candidate.partylistResult.votes}
                        columnWidth={"400px"}
                      />
                      </motion.div>
                    </ListItem>
                  );
                })
              }
            </ListContainer>
          </motion.div>
        ))
      }
    </Container>
  )
}
