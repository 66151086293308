import styled from 'styled-components';
import { OverallFullPageLayoutC } from '../overallFullPageLayoutC/OverallFullPageLayoutC';
import { OverallFullPageLayoutD } from '../overallFullPageLayoutD/OverallFullPageLayoutD';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useFirebaseField } from '../../../hooks/useFirebaseField';
import { LayoutFullPage } from '../stageLayouts/LayoutFullPage';
import { LayoutTransparent } from '../stageLayouts/LayoutTransparent';
import { OverallFullPageLayoutB } from '../overallFullPageLayoutB/OverallFullPageLayoutB';
import { OverallFullPageLayoutA } from '../overallFullPageLayoutA/OverallFullPageLayoutA';
import { OverallFullPageLayoutE } from '../overallFullPageLayoutE/OverallFullPageLayoutE';
import { OverallFullPageLayoutF } from '../overallFullPageLayoutF/OverallFullPageLayoutF';
import { DistrictsResultFullPage } from '../districsResultFullPage/DistrictsResultFullPage';
import { useParams } from 'react-router';
import { motion } from "framer-motion";

const currentSceneTransitions = {
  hidden: {
    display: "none",
    transition: {
      duration: 1,
      staggerChildren: 0.02,
      staggerDirection: -1
    }
  },
  show: {
    display: "block",
    visibility: "visible",
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.6
    }
  }
};

const StageContainer = styled.div`
  scale: ${props => props.previewMode ? Math.min(props.windowWidth / 1920, props.windowHeight / 1080) : 1};
  transform-origin: left top;
  background-color: #000;
`;

export const CurrentScene = ({ sceneTemplateCode, params }) => {
  switch (sceneTemplateCode) {
    case 'overall-a':
      return <OverallFullPageLayoutA {...params} />;
    case 'overall-b':
      return <OverallFullPageLayoutB {...params} />;
    case 'overall-c':
      return <OverallFullPageLayoutC {...params} />;
    case 'overall-d':
      return <OverallFullPageLayoutD {...params} />;
    case 'overall-e':
      return <OverallFullPageLayoutE {...params} />;
    case 'overall-f':
      return <OverallFullPageLayoutF {...params} />;
    case 'overall-g':
      return <DistrictsResultFullPage {...params} />;
    default:
      return <></>;
  }
}

export const StageLayout = ({ layoutCode, children }) => {
  switch (layoutCode) {
    case 'fullPage':
      return (
        <LayoutFullPage>{children}</LayoutFullPage>
      );
    case 'transparent':
      return (
        <LayoutTransparent>{children}</LayoutTransparent>
      )
  }
}

export const StagePage = ({ previewMode = false, source = 'live' }) => {
  const { stageCode } = useParams()

  const sceneField = source === 'live'
    ? `/stages/${stageCode}/liveScene`
    : `/stages/${stageCode}/previewScene`;

  const [activeScene] = useFirebaseField(sceneField, {
    sceneTemplateCode: 'emptyScene',
    params: {},
  });
  const size = useWindowSize();

  return (
    <StageContainer previewMode={previewMode} windowWidth={size.width} windowHeight={size.height}>
      <StageLayout layoutCode="fullPage">
        <motion.div variants={currentSceneTransitions}>
          <CurrentScene {...activeScene} />
        </motion.div>
      </StageLayout>
    </StageContainer>
  )
};
