

export const groupItems = (items, numberOfItems = 2) => {
  let outputItems = [];
  // for(let i = 0; i < items.length - (numberOfItems - 1); i++) {
  //   let tempItems = [];
  //   [...Array(numberOfItems)].map((item, index) => {
  //     tempItems.push(items[i + index]);
  //   })
  //   outputItems.push(tempItems);
  //   i = i + (numberOfItems - 1);
  // }
  for (let i=0; i < items.length; i+=numberOfItems) {
    const tempItems = items.slice(i, i+numberOfItems);
    outputItems.push(tempItems);
  }

  return outputItems;  
}