import { motion } from "framer-motion";
import { Counter } from "../counter/Counter";
import {
  Container,
  NumbersContainer,
  NumberItem,
  SeatNumber,
  VoteNumber,
  ImageWrapper,
  PartyName,
  TextContainer,
  Unit,
} from "./ItemCardSideBarLayoutB.style";
import { Border } from "../../templates/sidebar/SideBar.style";

export const ItemCardSideBarLayoutB = (props) => {
  const { orderNumber, imgUrl, partyName, totalSeats, totalVotes, partyColor } = props;

  return (
    <Container>
      <Border color={partyColor}>
        <div>{orderNumber}</div>
      </Border>
      <ImageWrapper>
        <motion.img src={imgUrl} />
      </ImageWrapper>
      <TextContainer>
        <PartyName>{partyName || "-"}</PartyName>
        {totalVotes && (
          <VoteNumber>
            <Counter from={0} to={totalVotes} />
            <Unit>คะแนน</Unit>
          </VoteNumber>
        )}
      </TextContainer>
      <NumbersContainer>
        <NumberItem>
          <SeatNumber>
            <Counter from={0} to={totalSeats ?? 0} />
          </SeatNumber>
          {/* {totalVotes &&
            <VoteNumber>
              <Counter from={0} to={totalVotes} />
            </VoteNumber>
          } */}
        </NumberItem>
      </NumbersContainer>
    </Container>
  );
};
