import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

import {
  SideBarContainer,
  BannerTop,
  BannerBottom,
  Logo,
  Container,
  ListWrapper,
  ListContainer,
  ListItem,
  ListHeader,
  TextContainer,
} from "../../templates/sidebar/SideBar.style";

import { SummaryScoreSideBar } from "../../atoms/summaryScoreSideBar/SummaryScoreSideBar";
import { ItemCardSideBarLayoutB } from '../../atoms/itemCardSideBarLayoutB/ItemCardSideBarLayoutB';

// import { getCountProgressOneDigit } from "../../../utils/overallResult";
// import { parseParams } from "../../../utils/params";
import { parties } from "../../../const/parties";
import { useSearchParams } from "react-router-dom";


export const SideBar5ItemsWithNamePageByProvince = () => {
  const [params] = useSearchParams();
  const lang = params.get('lang');

  const sizeItems = 5;
  const [isActiveIndex, setIsActiveIndex] = useState(0);
  const [partiesData, setPartiesData] = useState([]);
  const fullInfoResults = useSelector((state) => state.fullInfoResultReducer.fullInfoResults);

  useEffect(() => {
    if (fullInfoResults && fullInfoResults.length) {
      const tempCadidates = fullInfoResults.slice(0, sizeItems);
      setPartiesData(tempCadidates);
    }
  }, [fullInfoResults, isActiveIndex]);

  return (
    <div className="page-container">
      <SideBarContainer>
        <BannerTop
          style={{
            backgroundImage: `url("/static/images/l-shape-title.png")`,
          }}>
          <SummaryScoreSideBar
            titleTextFirstLine={lang === 'en' ? `Top 5 Constituency` : `5 อันดับผลคะแนน ส.ส. เขต`}
            titleTextSecondLine={`จ. ??????????`}
            subText={lang === 'en' ? `(Unofficial)` : `(อย่างไม่เป็นทางการ)`}
          />
        </BannerTop>
        <ListHeader>
          <TextContainer>{ (lang === 'en') ? 'Total (seats)' : 'รวม (ที่นั่ง)' }</TextContainer>
        </ListHeader>
        <Container>
          <ListWrapper>
            <ListContainer>
              {partiesData?.map((party, index) => {
                  return (
                    <ListItem key={`party-${party.partyRefCode}`}>
                      <motion.div
                        initial="visible"
                        // animate={{
                        //   backgroundColor: index === 0 ? "var(--ciBlue)" : "var(--white)",
                        //   color: index === 0 ? "var(--white)" : "var(--ciBlue)",
                        //   transition: {
                        //     type: "tween",
                        //     duration: 1,
                        //   },
                        // }}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <ItemCardSideBarLayoutB
                          orderNumber={index + 1}
                          imgUrl={`${parties[party.partyRefCode]?.images.logoImageUrl}`}
                          partyName={`${parties[party.partyRefCode]?.translations[lang || 'th'].name}`}
                          totalSeats={party.totalResult.seats}
                          totalVotes={party.totalResult.votes}
                          partyColor={parties[party.partyRefCode]?.color}
                        />
                      </motion.div>
                    </ListItem>
                  );
                })
              }
            </ListContainer>
          </ListWrapper>
        </Container>
        {/* <BannerBottom
          style={{
            backgroundImage: `url("/static/images/l-shape-footer.png")`,
          }}
        >
          <Logo>
            <img
              src="/static/images/logo.png"
              alt="logo"
              width="200" />
          </Logo>
        </BannerBottom> */}
      </SideBarContainer>
    </div>
  )
}
