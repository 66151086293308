import { createSlice } from "@reduxjs/toolkit";
import { keyBy } from "lodash";

const sceneTemplates = [
  {
    title: '[Full แบบที่ 1] จำนวนที่นั่ง ส.ส. เขต รายภาค',
    code: 'overall-c',
    defaultParams: {}
  },
  {
    title: '[Full แบบที่ 2] จำนวนที่นั่ง ส.ส. เขต รวมทั่วประเทศ',
    code: 'overall-b',
    defaultParams: {}
  },
  {
    title: '[Full แบบที่ 3] จำนวนที่นั่ง ส.ส. ทั่วประเทศ (เขต+บช.รายชื่อ)',
    code: 'overall-d',
    defaultParams: {}
  },
  {
    title: '[Full แบบที่ 7] จำนวนที่นั่ง ส.ส. เขต รายจังหวัด (เลือกจังหวัดได้)',
    code: 'overall-f',
    defaultParams: {
      provinceRefCode: '10',
    },
  },
  {
    title: '[Full แบบที่ 8] TOP 3 คะแนนรายเขต (เลือกเขตได้)',
    code: 'overall-g',
    defaultParams: {
      areaRefCode: '1001',
    },
  },
  {
    title: '[Full Special] สส บัญชี คะแนนพรรค Top 8',
    code: 'overall-e',
    defaultParams: {}
  },
];

const initialState = {
  sceneTemplateCodes: sceneTemplates.map(template => template.code),
  sceneTemplatesByCode: keyBy(sceneTemplates, template => template.code),
};

export const sceneTemplateSlice = createSlice({
  name: "sceneTemplate",
  initialState: initialState,
  reducers: {
  },
});

export default sceneTemplateSlice.reducer;
