import styled from 'styled-components';

export const PartyWrapper = styled.div`
  /* margin: 0 auto; */
  flex: 1;
  margin-top: 32px;

  .party-item-container {
    flex: 1;
  }
`;

export const TableWrapper = styled.div`
  padding: 0 52px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
