import { CandidateListFullPage } from "../../molecules/candidateListFullPage/CandidateListFullPage";
import { useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';

import {
  TemplateFullPageContainer,
  Wrapper,
  Logo,
  Container,
  TitleContainer,
  Title,
  Subtitle,
  CandidateWrapper,
  ContentContainer,
} from "./OverallFullPageLayoutA.style"

export const OverallFullPageLayoutA = () => {
  const overlayInfo = useSelector((state) => state.miscellaneousReducer.overlay);
  const fullInfoResults = useSelector((state) => state.fullInfoResultReducer.fullInfoResults);

  const [ params ] = useSearchParams();
  const lang = params.get('lang');

  return (
    <TemplateFullPageContainer>
      <Container
        style={{
          backgroundImage: `url('/static/images/graphic-bg.png')`,
        }}>
        <Wrapper>
          <ContentContainer>
            <TitleContainer>
              { 
                (lang === 'en') ? 
                  (<Title>{overlayInfo.unofficialCountingScore}</Title>) 
                  : (<><Title>{overlayInfo.countingScore}</Title><Subtitle>{overlayInfo.unofficial}</Subtitle></>) 
              }
            </TitleContainer>
            <CandidateWrapper>
              <CandidateListFullPage 
                candidateList={fullInfoResults} />
            </CandidateWrapper>
          </ContentContainer>
          <Logo>
            <img 
              src="/static/images/logo.png" 
              alt="logo" 
              width="120" />
          </Logo>          
        </Wrapper>
      </Container>
    </TemplateFullPageContainer>
  )
}