import { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import { motion } from "framer-motion";

import { groupItems } from "../../../utils/groupItems";
import { parseParams } from "../../../utils/params";
import { useIntervalOrClick } from "../../../utils/effects";
import { PartyCardFullPage } from "../../atoms/partyCardFullPage/PartyCardFullPage"
import { parties } from "../../../const/parties";

import {
  Container,
  ListContainer,
  ListItem,
} from "./PartyListFullPage.style"
import { useSelector } from "react-redux";
import { orderBy, sortBy } from "lodash";

const container = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      staggerChildren: 0.02,
      staggerDirection: -1
    }
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
      staggerChildren: 0.1,
      delayChildren: 0.6
    }
  },
  exit: {
    display: "none",
    transition: {
      duration: 1,
      staggerChildren: 0.1,
      delayChildren: 0.6
    }
  }
};

const itemVariants = {
  hidden: {
    opacity: 0,
    y: 80,
    transition: { type: "tween", duration: 2 }
  },
  show: {
    opacity: 1,
    y: 0,
    transition: { type: "tween", duration: 0.6 }
  }
};

const aggregateStatsByParty = (fullInfoResultsByDristrict, areasByRefCode) => {
  const entries = {};

  for (var entry of fullInfoResultsByDristrict) {
    const winner = entry.leadingCandidateResults[0];
    const regionRefCode = areasByRefCode[entry.areaRefCode].regionRefCode;
    const partyRefCode = winner.partyRefCode;

    var entry = entries[partyRefCode];
    if (!entry) {
      entry = {
        partyRefCode,
        winnersByRegionRefCode: {
          '1': 0,
          '2': 0,
          '3': 0,
          '4': 0,
          '5': 0,
          '6': 0,
          '7': 0,
        },
        totalWinners: 0,
      };
      entries[partyRefCode] = entry;
    }

    entry.totalWinners += 1;
    entry.winnersByRegionRefCode[regionRefCode] += 1;
  }

  return entries;
}

export const PartyListFullPage = ({ sizeItems = 15, partyList = [] }) => {
  const areasByRefCode = useSelector((state) => state.areaReducer.areasByRefCode);

  const fullInfoResultsByDristrict = useSelector((state) => {
    return state.districtVoteResultReducer.districts;
  });

  // const candidateInformation = useSelector((state) => state.candidateReducer.candidates);
  const { t } = parseParams({ t: 9000 });

  const [isActiveIndex, setIsActiveIndex] = useState(0);
  const [groupCandidates, setGroupCandidates] = useState([]);
  const [isReady, setIsReady] = useState(false);

  const resultsByRegionRefCode = useSelector((state) => state.regionVoteResultReducer.resultsByRegionRefCode);

  const [ params ] = useSearchParams();
  const lang = params.get('lang');
  console.log('get lang', lang);

  useEffect(() => {
    if (partyList && partyList.length && Object.values(areasByRefCode).length > 0) {
      const tempCadidates = partyList.slice(0, sizeItems);
      const statsByParty = aggregateStatsByParty(fullInfoResultsByDristrict, areasByRefCode);

      var result = [];
      for (var entry of tempCadidates) {
        result.push({
          ...entry,
          winnersByRegionRefCode: statsByParty[entry.partyRefCode]?.winnersByRegionRefCode ?? {}
        })
      }
      result = orderBy(result, (item) => item.areaResult.seats, 'desc');
      setGroupCandidates(groupItems(result, 8));
      setIsReady(true);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyList, fullInfoResultsByDristrict, areasByRefCode]);

  useIntervalOrClick({
    isReady,
    delay: t,
    acceptClick: true,
    callback: () => {
      if (isActiveIndex >= (groupCandidates.length - 1)) {
        setIsActiveIndex(0)
      } else {
        setIsActiveIndex((index) => index + 1);
      }
    }
  });

  return (
    <Container>
      {
        groupCandidates.map((group, idx) => (
          <motion.div
            variants={container}
            initial="hidden"
            animate={isActiveIndex === idx ? "show" : "exit"}
            style={{width: '100%', height: '100%'}}
            key={`page-${idx}`}>
            <ListContainer>
              {
                group?.map((candidate) => {
                  return (
                    <ListItem
                      key={`party-${candidate.partyRefCode}`}
                      className="party-item-container"
                      style={{
                        backgroundImage: `url('/static/images/h-list-bg.png')`,
                      }}
                    >
                      <motion.div
                        variants={itemVariants}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      >
                      <PartyCardFullPage
                        imgUrl={`${parties[candidate.partyRefCode]?.images.logoImageUrl}`}
                        partyName={`${lang === 'en' ? parties[candidate.partyRefCode]?.translations?.en?.name : parties[candidate.partyRefCode]?.translations?.th?.name}`}
                        northernScore={resultsByRegionRefCode['1'].allCandidateResults.find(x => x.partyRefCode === candidate.partyRefCode)?.areaResultSeats ?? 0}
                        centralScore={resultsByRegionRefCode['2'].allCandidateResults.find(x => x.partyRefCode === candidate.partyRefCode)?.areaResultSeats ?? 0}
                        isanScore={resultsByRegionRefCode['3'].allCandidateResults.find(x => x.partyRefCode === candidate.partyRefCode)?.areaResultSeats ?? 0}
                        easternScore={resultsByRegionRefCode['5'].allCandidateResults.find(x => x.partyRefCode === candidate.partyRefCode)?.areaResultSeats ?? 0}
                        southernScore={resultsByRegionRefCode['6'].allCandidateResults.find(x => x.partyRefCode === candidate.partyRefCode)?.areaResultSeats ?? 0}
                        bkkScore={resultsByRegionRefCode['7'].allCandidateResults.find(x => x.partyRefCode === candidate.partyRefCode)?.areaResultSeats ?? 0}
                        totalScore={candidate.areaResult.seats}
                      />
                      </motion.div>
                    </ListItem>
                  );
                })
              }
            </ListContainer>
          </motion.div>
        ))
      }
    </Container>
  )
}
