import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { useSearchParams } from 'react-router-dom';

import {
  SideBarContainer,
  BannerTop,
  BannerBottom,
  Logo,
  Container,
  ListWrapper,
  ListContainer,
  ListItem,
  ListHeader,
  TextContainer,
} from "../../templates/sidebar/SideBar.style";

import { CandidateCardSideBar } from "../../atoms/candidateCardSideBar/CandidateCardSideBar";
import { SummaryScoreSideBar } from "../../atoms/summaryScoreSideBar/SummaryScoreSideBar";
import { parties } from "../../../const/parties";

export const SideBar5CandidatesPage = () => {
  const sizeItems = 5;
  const [isActiveIndex, setIsActiveIndex] = useState(0);
  const [partiesData, setPartiesData] = useState([]);
  const fullInfoResults = useSelector((state) => state.fullInfoResultReducer.fullInfoResults);

  const [params] = useSearchParams();
  const lang = params.get('lang');

  useEffect(() => {
    if (fullInfoResults && fullInfoResults.length) {
      const tempCadidates = fullInfoResults.slice(0, sizeItems);
      setPartiesData(tempCadidates);
    }
  }, [fullInfoResults, isActiveIndex]);

  return (
    <div className="page-container">
      <SideBarContainer>
        <BannerTop
          style={{
            backgroundImage: `url("/static/images/l-shape-title.png")`,
          }}>
          <SummaryScoreSideBar
            titleTextFirstLine={lang === 'en' ? `Top 5 Number of seats` : `5 อันดับจำนวนที่นั่ง`}
            titleTextSecondLine={lang === 'en' ? `Constituency + Party-list` : `ส.ส. เขต + บัญชีรายชื่อ`}
            subText={lang === 'en' ? `Overall` : `ทั่วประเทศ`}
            smallSecondLine={true}
          />
        </BannerTop>
        <ListHeader>
          <TextContainer>เขต</TextContainer>
          <TextContainer>บช.รายชื่อ</TextContainer>
          <TextContainer>รวม</TextContainer>
        </ListHeader>
        <Container>
          <ListWrapper>
            <ListContainer>
              {partiesData?.map((party, index) => {
                  return (
                    <ListItem key={`party-${party.partyRefCode}`}>
                      <motion.div
                        initial="visible"
                        // animate={{
                        //   backgroundColor: party.rank === 1 ? "var(--ciBlue)" : "var(--white)",
                        //   color: party.rank === 1 ? "var(--white)" : "var(--ciBlue)",
                        //   transition: {
                        //     type: "tween", 
                        //     duration: 1,
                        //   },
                        // }}
                        style={{ 
                          width: "100%",
                          height: "100%",
                        }}>
                      <CandidateCardSideBar
                        orderNumber={party.rank}
                        imgUrl={`${parties[party.partyRefCode]?.images.logoImageUrl}`}
                        areaSeats={party.areaResult.seats}
                        // areaVotes={party.areaResult.votes}
                        partylistSeats={party.partylistResult.seats}
                        // partylistVotes={party.partylistResult.votes}
                        totalSeats={party.totalResult.seats}
                        partyColor={parties[party.partyRefCode]?.color}
                      />
                      </motion.div>
                    </ListItem>
                  );
                })
              }      
            </ListContainer>
          </ListWrapper>
        </Container>
        {/* <BannerBottom
          style={{
            backgroundImage: `url("/static/images/l-shape-footer.png")`,
          }}
        >
          <Logo>
          <img 
            src="/static/images/logo.png" 
            alt="logo" 
            width="200" />
        </Logo>
        </BannerBottom> */}
      </SideBarContainer>
    </div>
  )
}

