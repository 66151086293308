import styled from "styled-components";

// export const ContentContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   height: 100%;
// `;

export const PartyWrapper = styled.div`
  margin: 0 auto;
  flex: 1;

  .party-item-container {
    flex: 1;
  }
`

// export const Container = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   background-size: cover;
//   background-repeat: no-repeat;

//   width: 1920px;
//   height: 1080px;
// `

// export const Wrapper = styled.div`
//   position: relative;
//   width: 1779px;
//   height: 987px;
//   background: rgba(255 255 255 / 80%);
// `

// export const TitleWrapper = styled.div`
//   width: 100%;
//   padding: 32px 116px 24px;
//   background-color: var(--ciWhite);
// `

// export const TitleContainer = styled.div`
//   display: flex;
//   align-items: flex-end;
//   gap: 16px;
//   padding-right: 8px;
// `

// export const Title = styled.span`
//   font-size: 80px;
//   font-weight: 700;
//   line-height: 1;
//   color: var(--ciBlue);
// `

// export const Subtitle = styled.span`
//   font-size: 40px;
//   font-weight: 700;
//   line-height: 1.4;
//   color: var(--ciOrange);
// `

// export const RibbonWrapper = styled.div`
//   display: flex;
//   gap: 62px;
//   text-align: left;
//   padding-left: 116px;
  
//   background-position: right;
//   background-repeat: no-repeat;

//   width: 100%;
//   height: 72px;
// `

// export const Unit = styled.span`
//   font-size: 80%;
//   margin-left: 4px;
// `

export const TableWrapper = styled.div`
  padding: 0 52px;
  width: 100%;
`

export const TableHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 2px;
  padding-top: 16px;
  padding-bottom: 8px;
  padding-right: 60px;
`

export const TableHeaderText = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: var(--grey900);

  text-align: center;

  width: 155px;

  &.total{
    color: var(--ciOrange);
  }
`
