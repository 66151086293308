import { createSlice } from "@reduxjs/toolkit";
import { keyBy } from "lodash";

const initialState = {
  allRegionRefCodes: [],
  allRegionOptions: [],
  regionsByRefCode: {},
};

export const regionsSlice = createSlice({
  name: "region",
  initialState: initialState,
  reducers: {
    setRegions: (state, action) => {
      const allRegionRefCodes = action.payload.regions.map(region => region.refCode);
      const allRegionOptions = action.payload.regions.map(region => ({
        value: region.refCode,
        label: region.name,
      }));
      const regionsByRefCode = keyBy(action.payload.regions, region => region.refCode)

      return {
        allRegionRefCodes,
        allRegionOptions,
        regionsByRefCode,
      };
    },
  },
});

export const { setRegions } = regionsSlice.actions;

export default regionsSlice.reducer;
