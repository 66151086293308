import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';

import { parseParams } from '../../../utils/params';
import { useIntervalOrClick } from '../../../utils/effects';
import { CandidateCard } from '../../atoms/candidateCard/CandidateCard';
import { Counter } from '../../atoms/counter/Counter';

import {
  LowerBarContainer,
  TitleContainer,
  Container,
  ListWrapper,
  ListContainer,
  ListItem,
} from '../../templates/lowerBar/LowerBar.style';
import { BannerTitle, Logo } from './LowerBar50ItemsPage.style';

import { parties } from '../../../const/parties';
import { setAreaRegionIndex } from '../../../store/lowerthirdAreaResult/reducers';

import {
  container,
  containerVariants,
  itemTitleVariants,
  itemVariants
} from '../../templates/lowerBar/LowerBar';
import { useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import { useSearchParams } from 'react-router-dom';


const LowerBar50ItemsItem = ({ items }) => {
  const [params] = useSearchParams();
  const lang = params.get('lang');

  return items ? (
    <div style={{ width: '75%' }}>
      <motion.div
        variants={container}
        initial='hidden'
        animate='show'
        exit='hidden'
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ overflow: 'hidden' }}>
          {items.area.province.translations[lang ?? 'th'].name
            .split('')
            .map((char, index) => {
              return (
                <motion.span
                  key={char + '-' + index}
                  variants={itemTitleVariants}
                >
                  {char}
                </motion.span>
              );
            })}
        </div>
      </motion.div>
      <motion.div
        variants={container}
        initial='hidden'
        animate='show'
        exit='hidden'
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '16px',
        }}
      >
        <div
          style={{
            overflow: 'hidden',
            marginRight: '8px',
            fontSize: '24px',
            fontWeight: '700',
          }}
        >
          {`${lang === 'en' ? 'CON.' : 'เขต'} ${items.area.areaNo}`
            .split('')
            .map((char, index) => {
              return (
                <motion.span
                  key={char + '-' + index}
                  variants={itemTitleVariants}
                >
                  {char}
                </motion.span>
              );
            })}
        </div>
        <div
          style={{
            overflow: 'hidden',
            fontSize: '18px',
            fontWeight: '700',
            color: 'var(--ciBlue)',
            marginTop: "4px",
          }}
        >
          {/** แก้ค่า to={??? || 0} */}
          {
            items.dataSource === 'ect'
              ? (<>{lang === 'en' ? `Counted` : `หน่วยที่นับแล้ว`} <Counter from={0} to={items.progress ?? 0} />%</>)
              : <>{lang === 'en' ? `(Source from ADTEB Volunteer)` : `(คะแนนจากอาสาฯ)`}</>
          }
        </div>
      </motion.div>
    </div>
  ) : null;
}

const LowerBar50ItemsRight = ({ items }) => {
  const [params] = useSearchParams();
  const lang = params.get('lang');

  return items ? (
    <ListContainer>
      {items?.leadingCandidateResults?.map((entry, index) => {
        return (
          <ListItem key={`candidate-${entry.candidateRefCode}-${index}`}>
            <motion.div
              variants={itemVariants(index)}
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'var(--white)',
              }}
            >
              <CandidateCard
                firstName={entry.candidate.translations[lang ?? 'th'].firstName}
                lastName={entry.candidate.translations[lang ?? 'th'].lastName}
                imgUrl={entry.candidate.images.portraitImageUrl}
                score={entry.totalVotes}
                bgColor={`${parties[entry.candidate.partyRefCode]?.color}`}
                logoUrl={`${parties[entry.candidate.partyRefCode]?.images.logoImageUrl}`}
              />
            </motion.div>
          </ListItem>
        );
      })}
    </ListContainer>
  ) : null;
}

export const LowerBar50ItemsPage = () => {
  const { t, maxItems } = parseParams({ t: 9000, maxItems: 50 });

  const allAreaRefCodes = useSelector((state) => {
    return state.areaReducer.allAreaRefCodes;
  });
  const results = useSelector((state) => {
    return state.districtVoteResultReducer.districts;
  });
  const resultsByAreaRefCode = useSelector((state) => {
    return state.districtVoteResultReducer.resultsByAreaRefCode;
  });
  const isReady = useSelector((state) => {
    return allAreaRefCodes.length > 0
      && results.length > 0;
  });

  const [recentUpdatedAreaRefCodes, setRecentUpdatedAreaRefCodes] = useState([]);
  const [currentAreaRefCode, setCurrentAreaRefCode] = useState('8101');

  useIntervalOrClick({
    isReady,
    delay: t,
    acceptClick: true,
    callback: () => {
      console.log('Start');

      var clonedRecentUpdatedAreaRefCodes = [...recentUpdatedAreaRefCodes];
      console.log('Remaining items', clonedRecentUpdatedAreaRefCodes.length);

      // Try refill
      if (clonedRecentUpdatedAreaRefCodes.length <= 0) {
        console.log('Finding update...');
        const newRecentUpdated = orderBy(
          [...results],
          [
            (item) => item.sourceUpdatedBy,
            (item) => item.progress,
          ],
          [
            'desc',
            'desc',
          ],
        );
        clonedRecentUpdatedAreaRefCodes = newRecentUpdated.map(item => item.areaRefCode).slice(0, maxItems);
      }

      // Show the first
      if (clonedRecentUpdatedAreaRefCodes.length > 0) {
        const newAreaRefCode = clonedRecentUpdatedAreaRefCodes.shift();
        setRecentUpdatedAreaRefCodes(clonedRecentUpdatedAreaRefCodes);
        setCurrentAreaRefCode(newAreaRefCode);
      }
    },
  });

  if (!isReady) {
    return <></>;
  }

  return (
    <div className="page-container">
      <motion.div
        variants={containerVariants}
        initial='hidden'
        animate='show'
        exit='hidden'
        style={{
          width: '100%',
          // overflowY: 'clip',
          position: 'absolute',
          bottom: '115px',
          left: 0,
          right: 0,
        }}
      >
        <LowerBarContainer>
          <TitleContainer>
            <BannerTitle
              style={{
                backgroundImage: `url("/static/images/lower-title.png")`,
                backgroundSize: 'cover',
              }}
            >
              <Logo>
                <img src='/static/images/logo.png' alt='logo' width='120' />
              </Logo>
              <LowerBar50ItemsItem items={resultsByAreaRefCode[currentAreaRefCode]} />
            </BannerTitle>
          </TitleContainer>
          <Container>
            <ListWrapper>
              <LowerBar50ItemsRight items={resultsByAreaRefCode[currentAreaRefCode]} />
            </ListWrapper>
          </Container>
          <div
            style={{
              height: '155px',
              width: '398px',
              // backgroundColor: 'var(--white)',
              backgroundImage: `url("/static/images/lower-right-banner.png")`,
              backgroundSize: 'cover',
              zIndex: 3,
            }}
          ></div>
        </LowerBarContainer>
      </motion.div>
    </div>
  );
};
