import { Counter } from '../counter/Counter';
import {
  Container,
  TitleContainer,
  Title,
  ImageWrapper,
  Value,
  Ranking,
  PartyWrapper,
} from './PartyCardFullPageCustom.style';

export const PartyCardFullPageCustom = (props) => {
  const {
    imgUrl,
    partyName,
    partyRank,
    column1Value,
    column2Value,
    column3Value,
    column4Value,
    columnWidth,
  } = props;

  return (
    <Container className='party-container'>
      <TitleContainer className='title-container'>
        <Ranking>{partyRank > 0 ? `${partyRank}` : ''}</Ranking>
        <PartyWrapper>
          <ImageWrapper className='image-wrapper'>
            {
              imgUrl && (<img src={imgUrl} alt={imgUrl} />)
            }
          </ImageWrapper>
          <Title className='full-name'>{partyName}</Title>
        </PartyWrapper>
      </TitleContainer>
      {column1Value >= 0 && (
        <Value className='score' columnWidth={columnWidth}>
          <Counter from={0} to={column1Value} />
        </Value>
      )}
      {column2Value >= 0 && (
        <Value className='score' columnWidth={columnWidth}>
          <Counter from={0} to={column2Value} />
        </Value>
      )}
      {column3Value >=0 && (
        <Value className='score' columnWidth={columnWidth}>
          <Counter from={0} to={column3Value} />
        </Value>
      )}
      {column4Value >= 0 && (
        <Value className='score' columnWidth={columnWidth}>
          <Counter from={0} to={column4Value} />
        </Value>
      )}
    </Container>
  );
};
