import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';

import { parseParams } from '../../../utils/params';
import { useIntervalOrClick } from '../../../utils/effects';
import { CandidateCard } from '../../atoms/candidateCard/CandidateCard';
import { Counter } from '../../atoms/counter/Counter';

import {
  LowerBarContainer,
  TitleContainer,
  Container,
  ListWrapper,
  ListContainer,
  ListItem,
} from '../../templates/lowerBar/LowerBar.style';
import { BannerTitle, Logo } from './LowerBarRegionsPage.style';

import { parties } from '../../../const/parties';
import { setAreaRegionIndex } from '../../../store/lowerthirdAreaResult/reducers';

import {
  container,
  containerVariants,
  itemTitleVariants,
  itemVariants
} from '../../templates/lowerBar/LowerBar';

const LowerbarRegionItem = ({ region }) => {
  return region ? (
    <div
      style={{ width: '50%' }}
    >
      <motion.div
        variants={container}
        initial='hidden'
        animate='show'
        exit='hidden'
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ overflow: 'hidden' }}>
          {region.area.province.translations.th.name
            .split('')
            .map((char, index) => {
              return (
                <motion.span
                  key={char + '-' + index}
                  variants={itemTitleVariants}
                >
                  {char}
                </motion.span>
              );
            })}
        </div>
      </motion.div>
      <motion.div
        variants={container}
        initial='hidden'
        animate='show'
        exit='hidden'
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '16px',
        }}
      >
        <div
          style={{
            overflow: 'hidden',
            marginRight: '8px',
            fontSize: '24px',
            fontWeight: '700',
          }}
        >
          {`เขต ${region.area.areaNo}`
            .split('')
            .map((char, index) => {
              return (
                <motion.span
                  key={char + '-' + index}
                  variants={itemTitleVariants}
                >
                  {char}
                </motion.span>
              );
            })}
        </div>
        <div
          style={{
            overflow: 'hidden',
            fontSize: '18px',
            fontWeight: '700',
            color: 'var(--ciBlue)',
            marginTop: "4px",
          }}
        >
          {/** แก้ค่า to={??? || 0} */}
          {/**(นับแล้ว <Counter from={0} to={region.progress ?? 0} />%)**/}
        </div>
      </motion.div>
    </div>
  ) : null;
}

const LowerbarRegionItemRight = ({ region }) => {
  return region ? (
    <ListContainer>
      {region?.leadingCandidateResults?.map((entry, index) => {
        return (
          <ListItem key={`candidate-${entry.candidateRefCode}-${index}`}>
            <motion.div
              variants={itemVariants(index)}
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'var(--white)',
              }}
            >
              <CandidateCard
                firstName={entry.candidate.translations.th.firstName}
                lastName={entry.candidate.translations.th.lastName}
                imgUrl={entry.candidate.images.portraitImageUrl}
                score={entry.totalVotes}
                bgColor={`${parties[entry.candidate.partyRefCode]?.color}`}
                logoUrl={`${parties[entry.candidate.partyRefCode]?.images.logoImageUrl}`}
              />
            </motion.div>
          </ListItem>
        );
      })}
    </ListContainer>
  ) : null;
}

export const LowerBarRegionsPage = () => {
  const { t } = parseParams({ t: 9000 });

  const fullInfoResultsByDristrict = useSelector((state) => {
    return state.districtVoteResultReducer.districts;
  });
  const isReady = useSelector((state) => {
    return state.districtVoteResultReducer.districts !== undefined;
  });
  const isActiveIndex = useSelector((state) => {
    return state.lowerthirdAreaResultReducer.areaRegionIndex;
  });

  const dispatch = useDispatch();

  useIntervalOrClick({
    isReady,
    delay: t,
    acceptClick: true,
    callback: () => {
      console.log('isActiveIndex', isActiveIndex);
      if (isActiveIndex >= 399) {
        dispatch(
          setAreaRegionIndex({
            areaRegionIndex: isActiveIndex + 1,
          })
        );
      } else {
        dispatch(
          setAreaRegionIndex({
            areaRegionIndex: isActiveIndex + 1,
          })
        );
      }
    },
  });

  return (
    <div className="page-container">
      <motion.div
        variants={containerVariants}
        initial='hidden'
        animate='show'
        exit='hidden'
        style={{
          width: '100%',
          // overflowY: 'clip',
          position: 'absolute',
          bottom: '115px',
          left: 0,
          right: 0,
        }}
      >
        <LowerBarContainer>
          <TitleContainer>
            <BannerTitle
              style={{
                backgroundImage: `url("/static/images/lower-title.png")`,
                backgroundSize: 'cover',
              }}
            >
              <Logo>
                <img src='/static/images/logo.png' alt='logo' width='120' />
              </Logo>
              <LowerbarRegionItem region={fullInfoResultsByDristrict[isActiveIndex]} />
            </BannerTitle>
          </TitleContainer>
          <Container>
            <ListWrapper>
              <LowerbarRegionItemRight region={fullInfoResultsByDristrict[isActiveIndex]} />
            </ListWrapper>
          </Container>
          <div
            style={{
              height: '155px',
              width: '398px',
              // backgroundColor: 'var(--white)',
              backgroundImage: `url("/static/images/lower-right-banner.png")`,
              backgroundSize: 'cover',
              zIndex: 3,
            }}
          ></div>
        </LowerBarContainer>
      </motion.div>
    </div>
  );
};
