import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';

import { canReportProgressPercent, getCountProgress } from "../../../utils/overallResult";
import { Counter } from "../../atoms/counter/Counter";

import {
  Container,
  TitleContainer,
  Title,
  Wrapper,
  Subtitle,
  ContentContainer,
  TitleWrapper,
  RibbonWrapper,
  Unit,
  CountedTextContainer,
  CountedValue,
  DescriptionContainer,
} from "../../templates/overallFullPage/OverallFullPage.style"

import {
  PartyWrapper,
  TableWrapper,
  TableHeader,
  TableHeaderText,
} from "./OverallFullPageLayoutE.style"

import { PartyListFullPageForLayoutE } from '../../molecules/partyListFullPage/PartyListFullPageForLayoutE';
import { containerVariants, countedTextMotionStyle, descriptionMotionStyle } from '../../templates/overallFullPage/OverallFullPage';

export const OverallFullPageLayoutE = () => {
  const fullInfoResults = useSelector((state) => state.fullInfoResultReducer.fullInfoResults);
  const summary = useSelector((state) => state.overallResultReducer.summary);
  const overlayInfo = useSelector((state) => state.miscellaneousReducer.overlay);

  const [ params ] = useSearchParams();
  const lang = params.get('lang');

  return (
    <div className="page-container">
        <Container
          style={{
            backgroundImage: `url('/static/images/graphic-bg.png')`,
          }}>
          <Wrapper>
            <ContentContainer>
              <TitleWrapper>
                <TitleContainer>
                  {(lang === 'en') ? (
                    <>
                      {/** เปลี่ยน Title กับ Subtitle */}
                      {/* <Title>{overlayInfo.unofficialCountingScore}</Title> */}
                      <Title>Result of Party-list MPs overall</Title>
                      {/* <Subtitle>(Unofficial)</Subtitle> */}
                    </>
                  ) : (
                    <>
                      {/** Title ของหน้า e.g. ผลการนับคะแนน ส.ส. */}
                      {/* <Title>{overlayInfo.countingScore}</Title> */}
                      <Title>ผลคะแนน ส.ส. บัญชีรายชื่อ ทั่วประเทศ</Title>

                      {/** Subtext ที่วงเล็บข้างหลัง title ของหน้า e.g. (อย่างไม่เป็นทางการ) */}
                      {/* <Subtitle>{overlayInfo.unofficial}</Subtitle> */}
                      {/* <Subtitle>(อย่างไม่เป็นทางการ)</Subtitle> */}
                    </>
                  )}
                </TitleContainer>
              </TitleWrapper>
              <RibbonWrapper
                style={{
                  backgroundImage: `url('/static/images/h-ribbon.png')`,
                }}
              >
                <motion.div
                  variants={containerVariants}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  style={descriptionMotionStyle}
                >
                  <DescriptionContainer>
                    { (lang === 'en') ? '(Unofficial)' : '(อย่างไม่เป็นทางการ)' }
                  </DescriptionContainer>
                </motion.div>
                <motion.div
                  variants={containerVariants}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  style={countedTextMotionStyle}
                >
                  {
                    canReportProgressPercent(summary)
                      ? <>
                        <CountedTextContainer>
                          { (lang === 'en') ? 'Counted' : 'นับแล้ว' }
                          <CountedValue>
                            <Counter from={0} to={getCountProgress(summary)} digit={1} />
                            <Unit>%</Unit>
                          </CountedValue>
                        </CountedTextContainer>
                      </>
                      : null
                  }
                </motion.div>
              </RibbonWrapper>
              <TableWrapper>
                <TableHeader>
                  <TableHeaderText>{ (lang === 'en') ? 'Party-list votes' : 'คะแนน ส.ส. บัญชีรายชื่อ' }</TableHeaderText>
                </TableHeader>
                <PartyWrapper>
                  <PartyListFullPageForLayoutE partyList={fullInfoResults} />
                </PartyWrapper>
              </TableWrapper>
            </ContentContainer>
          </Wrapper>
        </Container>
    </div>
  )
}
