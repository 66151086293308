import {Link } from "react-router-dom";

import {
  Container,
  ListItem,
  CardItem,
  Title,
  Subtitle,
  TabContainer,
  TabTitle,
  LinkItem
} from "./DashBoardPage.style";

export const DashBoardPage = () => {

  const search = window?.location?.search;

  const params = search ? search : '';

  return (
    <>
      <TabContainer>
        <TabTitle>เลือกภาษา</TabTitle>
        <Link to="/">
          <LinkItem isActived={!Boolean(params)}>ไทย</LinkItem>
        </Link>
        <Link to="?lang=en">
          <LinkItem isActived={Boolean(params)}>ENG</LinkItem>
        </Link>
      </TabContainer>
      <Container>
        <h3>L-Shape</h3>
        <ListItem>
          <Link to={`/l-shape${params}`} target="_blank">
            <CardItem>
              <Title>L-Shape (ทั่วประเทศ)</Title>
              <Subtitle>Sidebar 10 พรรคแรก (ส.ส. ทั่วประเทศ) + Lowerthird แบ่งเขต</Subtitle>
            </CardItem>
          </Link>
          <Link to={`/l-shape/1${params}`} target="_blank">
            <CardItem>
              <Title>L-Shape (ทั่วประเทศ)</Title>
              <Subtitle>Sidebar 5 พรรคแรก (ส.ส. ทั่วประเทศ) + Lowerthird แบ่งเขต</Subtitle>
            </CardItem>
          </Link>
          <Link to={`/l-shape/2${params}`} target="_blank">
            <CardItem>
              <Title>L-Shape (ตามภาค)</Title>
              <Subtitle>Sidebar 5 พรรคแรก (ส.ส. เขต ตามภาค) + Lowerthird แบ่งเขต</Subtitle>
            </CardItem>
          </Link>
          <Link to={`/l-shape/4${params}`} target="_blank">
            <CardItem>
              <Title>L-Shape (บัญชีรายชื่อ)</Title>
              <Subtitle>Sidebar 5 พรรคแรก (ส.ส. บัญชีรายชื่อ) + Lowerthird แบ่งเขต</Subtitle>
            </CardItem>
          </Link>
        </ListItem>

        <h3>Sidebar</h3>
        <ListItem>
          <Link to={`/sidebar/1${params}`}>
            <CardItem>
              <Title>Sidebar (10 พรรคแรก)</Title>
              <Subtitle>แสดงพรรคนำ 10 อันดับแรกของ ส.ส. ทั่วประเทศ</Subtitle>
            </CardItem>
          </Link>
          <Link to={`/sidebar/2${params}`} target="_blank">
            <CardItem>
              <Title>Sidebar (5 พรรคแรก)</Title>
              <Subtitle>แสดงพรรคนำ 5 อันดับแรกของ ส.ส. ทั่วประเทศ</Subtitle>
            </CardItem>
          </Link>
          <Link to={`/sidebar/3${params}`} target="_blank">
            <CardItem>
              <Title>Sidebar (5 พรรคแรก - มีชื่อพรรค) (ตามภาค)</Title>
              <Subtitle>แสดงพรรคนำ 5 อันดับแรกของ ส.ส. เขต ตามภาค</Subtitle>
            </CardItem>
          </Link>
          <Link to={`/sidebar/5${params}`} target="_blank">
            <CardItem>
              <Title>Sidebar (5 พรรคแรก - มีชื่อพรรค) (บัญชีรายชื่อ)</Title>
              <Subtitle>แสดงพรรคนำ 5 อันดับแรกของ ส.ส. บัญชีรายชื่อ</Subtitle>
            </CardItem>
          </Link>
        </ListItem>

        <h3>Lowerthird</h3>
        <ListItem>
          <Link to={`/lowerbar/area/1${params}`} target="_blank">
            <CardItem>
              <Title>Lowerthird (50 เขต อัปเดตล่าสุด)</Title>
              <Subtitle>แสดงผลแบ่งเขต 3 อันดับแรก หมุนเวียน 50 เขต อัปเดตล่าสุด</Subtitle>
            </CardItem>
          </Link>
          <Link to={`/lowerbar/area/2${params}`} target="_blank">
            <CardItem>
              <Title>Lowerthird (ตามภาค)</Title>
              <Subtitle>แสดงผลแบ่งเขต 3 อันดับแรก หมุนเวียน 400 เขต ตามภาค</Subtitle>
            </CardItem>
          </Link>
          {/* <Link to={`/lowerbar/overall${params}`}>
            <CardItem>
              <Title>Lower Bar Over All</Title>
              <Subtitle>แสดงผลคะแนนรวม 12 อันดับ แสดงผลครั้งละ 3 อันดับ</Subtitle>
            </CardItem>
          </Link> */}
        </ListItem>

        <h3>Full Page: Overall</h3>
        <ListItem>
          {/* <Link to={`/fullpage/overall/1${params}`}>
            <CardItem>
              <Title>Full Page Overall (auto)</Title>
              <Subtitle>แสดงผลคะแนนรวม 15 อันดับ แสดงผลครั้งละ 5 อันดับ</Subtitle>
            </CardItem>
          </Link> */}
          {/* <Link to={`/fullpage/overall/1?t=0${params && '&lang=en'}`}>
            <CardItem>
              <Title><span style={{color: 'red'}}>Full Page Overall (manual)</span></Title>
              <Subtitle>แสดงผลคะแนนรวม 15 อันดับ แสดงผลครั้งละ 5 อันดับ โดยใช้วิธีคลิก</Subtitle>
            </CardItem>
          </Link> */}
          <Link to={`/fullpage/overall/2${params}`} target="_blank">
            <CardItem>
              <Title>Full Page: Overall (รายภาค)</Title>
              <Subtitle>แสดงผลจำนวน ส.ส. เขต 8 อันดับแรก รายภาค</Subtitle>
            </CardItem>
          </Link>
          <Link to={`/fullpage/overall/3${params}`} target="_blank">
            <CardItem>
              <Title>Full Page: Overall (ทั่วประเทศ)</Title>
              <Subtitle>แสดงผลจำนวน ส.ส. เขต ทั่วประเทศ ครั้งละ 16 อันดับ</Subtitle>
            </CardItem>
          </Link>
          <Link to={`/fullpage/overall/4${params}`} target="_blank">
            <CardItem>
              <Title>Full Page: Overall (ทั่วประเทศ: เขต + บัญชีรายชื่อ)</Title>
              <Subtitle>แสดงผลจำนวน ส.ส. ทั่วประเทศ (เขต + บัญชีรายชื่อ) ครั้งละ 8 อันดับ</Subtitle>
            </CardItem>
          </Link>
          <Link to={`/fullpage/overall/5${params}`} target="_blank">
            <CardItem>
              <Title>Full Page: Overall (บัญชีรายชื่อ)</Title>
              <Subtitle>แสดงผลคะแนน ส.ส. บัญชีรายชื่อ ครั้งละ 8 อันดับ</Subtitle>
            </CardItem>
          </Link>
          <Link to={`/fullpage/overall/6${params}`} target="_blank">
            <CardItem>
              <Title>Full Page: Overall (ส.ส. เขต)</Title>
              <Subtitle>แสดงผล ส.ส. เขต รายขังหวัด ครั้งละ 8 อันดับ</Subtitle>
            </CardItem>
          </Link>
        </ListItem>

        <h3>Full Page: District</h3>
        <ListItem>
          <Link to={`/fullpage/area${params}`} target="_blank">
            <CardItem>
              <Title>Full Page: District</Title>
              <Subtitle>แสดงผลคะแนน ส.ส. เขต รายเขต โดยแสดงผู้ที่มีคะแนนนำ 3 อันดับในเขตนั้น</Subtitle>
            </CardItem>
          </Link>
          {/* <Link to={`/fullpage/manual/area${params}`}>
            <CardItem>
              <Title><span style={{color: 'red'}}>Full Page District (manual)</span></Title>
              <Subtitle>แสดงผลคะแนนรายเขต โดยแสดงผู้ที่มีคะแนนนำ 5 อันดับในเขตนั้นโดยใช้วิธีคลิก</Subtitle>
            </CardItem>
          </Link> */}
          <Link to={`/fullpage/area/1001${params}`} target="_blank">
            <CardItem>
              <Title>Full Page: District Detail</Title>
              <Subtitle>แสดงผลคะแนน ส.ส. เขต รายเขต โดยแสดงผู้ที่มีคะแนนนำ 3 อันดับในเขตนั้น แบบเจาะจงเขต</Subtitle>
            </CardItem>
          </Link>
        </ListItem>
      </Container>
    </>
  )
}
