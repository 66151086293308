import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
  gap: 8px;
  padding-right: 24px;
  height: 100%;
  position: relative;
`;

export const OrderNumber = styled.div`
  font-family: 'Avenir LT Std';
  /* font-size: 50px; */
  font-size: 44px;
  font-weight: 900;

  min-width: 70px;
  width: 70px;

  text-align: center;
  align-self: center;
`;

export const NumbersContainer = styled.div`
  display: flex;
`;

export const NumberItem = styled.div`
  /* margin-right: 32px; */
  padding-top: 8px;
  flex: 1;
  min-width: 100px;
  width: 100px;
  height: 100%;

  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: right;
`;

export const SeatNumber = styled.div`
  font-family: 'Avenir LT Std';
  /* font-size: 40px; */
  font-size: 44px;
  font-weight: 900;
  line-height: 1.2;
`;

export const VoteNumber = styled.div`
  font-family: 'Avenir LT Std';
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  padding-right: 4px;
`;

export const ImageWrapper = styled.div`
  /* position: absolute; */
  /* bottom: 0; */
  /* left: 0; */
  width: 70px;
  height: 70px;
  overflow: hidden;
  background-color: var(--white);
  border-radius: 6px;

  display: flex;
  justify-content: center;

  > img {
    max-height: 100%;
    margin-top: auto;
  }
`;
