import styled, {css} from "styled-components";

export const SideBarContainer = styled.div`
  width: 479px;
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: var(--ciBlue);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

export const ListWrapper = styled.div`
  flex: 1;
`;

export const ListItem = styled.div`
  flex: 0 0 100px;
  margin-bottom: 4px;
  position: relative;
  overflow: hidden;
  background: var(--ciWhite);
  color: var(--ciBlue);

  &:first-child {
    /* margin-top: 2px; */
  }
`;

export const Border = styled.div`
  background: ${(props) => props.color || 'grey'};
  width: 40px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  clip-path: polygon(
    0% 0%,
    calc(100% - 12px) 0%,
    100% 12px,
    100% 100%,
    0% 100%
  );

  > div {
    color: var(--ciWhite);
    font-weight: 800;
    font-size: 32px;
  }
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ListHeader = styled.div`
  display: flex;
  justify-content: flex-end;

  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 24px;
  background-color: var(--ciOrange);
`;

export const TextContainer = styled.div`
  width: 100px;
  text-align: end;
  color: var(--white);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3;
`;

export const BannerBottom = styled.div`
  background-size: cover;
  width: 100%;
  flex: 0 0 270px;
  position: relative;
`;

export const BannerTop = styled.div`
  background-size: cover;
  width: 100%;

  flex: 0 0 213px;
  position: relative;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start; */
  /* align-items: flex-start; */
  background-position: top right;
  align-items: center;
  justify-content: flex-start;

  padding-top: 46px;
  /* padding-right: 36px; */
  padding-bottom: 6px;
  padding-left: 40px;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: 100%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */

  /* height: 100%; */
`;

export const TitleAContainer = styled.div`
  font-family: "sukhumvitset";
  font-size: 32px;
  font-weight: 700;
  line-height: 1.4;
  /* color: var(--white); */
  color: var(--ciBlue);

  width: 80%;
  /* text-align: center; */
`;

export const TitleBContainer = styled.div`
  font-family: "sukhumvitset";
  font-size: 44px;
  font-weight: 700;
  line-height: 1.4;
  /* color: var(--white); */
  color: white;

  width: fit-content;
  padding: 4px 12px;
  background: var(--ciBlue);
  margin-top: 12px;
  /* text-align: center; */

  ${props => props.small && css`
    font-size: 36px;
  `}
`;

export const SubTitleContainer = styled.div`
  position: relative;
  display: flex;
  /* align-items: center; */
  /* margin-top: 8px; */
  /* justify-content: center; */
  /* min-height: 48px; */
`;

export const ResultSubtitle = styled.div`
  font-family: "sukhumvitset";
  /* font-size: 21px; */
  /* font-weight: 700; */
  font-size: 30px;
  font-weight: 500;
  line-height: 1.4;
  /* color: var(--ciOrange); */
  color: var(--ciOrange);
  font-weight: 700;

  /* height: 100%; */
  margin-top: 8px;
`;

export const Logo = styled.div`
  position: absolute;
  top: 24px;
  /* left: 70px; */
  right: 24px;
`;

export const Unit = styled.span`
  margin-left: 4px;
  font-size: 80%;
`;
