// import { motion } from "framer-motion";
// import { useSelector } from 'react-redux';
// import { useSearchParams } from 'react-router-dom';

import {
  TopContainer,
  TitleAContainer,
  TitleBContainer,
  SubTitleContainer,
  ResultSubtitle,
  TitleWrapper,
} from '../../templates/sidebar/SideBar.style';

export const SummaryScoreSideBar = (props) => {
  // const overlayInfo = useSelector((state) => state.miscellaneousReducer.overlay);
  const {
    titleTextFirstLine,
    titleTextSecondLine,
    subText,
    smallSecondLine,
  } = props

  // const [params] = useSearchParams();
  // const lang = params.get('lang');

  return (
    <TopContainer>
      <TitleWrapper>
        {/** Title ของ SideBar บรรทัดแรก e.g. ผลนับคะแนน ส.ส., ผลนับคะแนน ส.ส. เขต, ผลนับคะแนน */}
        {<TitleAContainer>
          {/* { lang === 'en' ? overlayInfo.unofficialCountingScore : overlayInfo.countingScore } */}
          {/* {lang === 'en' ? '??????' : titleTextFirstLine} */}
          {titleTextFirstLine}
        </TitleAContainer>}

        {/** Title ของ SideBar บรรทัดที่ 2 e.g. ทั่วประเทศ, ภาคเหนือ, จ.พระนครศรีอยุธยา, ส.ส.บัญชีรายชื่อ */}
        <TitleBContainer small={smallSecondLine}>
          {/* { lang === 'en' ? overlayInfo.unofficialCountingScore : overlayInfo.countingScore } */}
          {/* {lang === 'en' ? '??????' : titleTextSecondLine} */}
          {titleTextSecondLine}
        </TitleBContainer>
      </TitleWrapper>

      <SubTitleContainer>
        {/** Subtext ของ SideBar e.g. (รวม 000 เขต อย่างไม่เป็นทางการ), (อย่างไม่เป็นทางการ) */}
        <ResultSubtitle>
          {/* { lang === 'en' ? '': overlayInfo.unofficial } */}
          {/* {lang === 'en' ? '' : subText} */}
          {subText}
        </ResultSubtitle>
      </SubTitleContainer>
    </TopContainer>
  );
};
