import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const ListWrapper = styled.div`
  flex: 1;
`

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 100%;
  margin: 0 auto;
`

export const ListItem = styled.div`
  margin-bottom: 10px;
  position: relative;
  width: 100%;
  height: 72px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
`