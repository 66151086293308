export const containerVariants = {
  hidden: {
    display: 'none',
    transition: {
      duration: 1,
      staggerChildren: 0.02,
      staggerDirection: -1,
    },
  },
  show: {
    display: 'block',
    visibility: 'visible',
    transition: {
      staggerChildren: 0.08,
      delayChildren: 0.2,
    },
  },
};

export const descriptionMotionStyle = {
  color: 'var(--ciWhite)',
  fontSize: '46px',
  fontWeight: '700',
  width: '1050px',
};

export const countedTextMotionStyle = {
  color: 'var(--ciWhite)',
  fontSize: '34px',
  fontWeight: '700',
};
