import styled from "styled-components";

export const LowerBarContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  /* overflow: hidden; */
  height: 155px;
  background-color: var(--white);
  /* background-color: var(--ciBlue); */
`;

export const QRCode = styled.div`
  position: absolute;
  right: 381px;
  top: -145px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4px;

  width: 100px;
  height: 145px;
  background-color: var(--ciBlue);

  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
`;

export const BannerLeft = styled.div``

export const BannerRight = styled.div``

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
  overflow: hidden;
  /* background-color: var(--white); */
`;

export const TitleContainer = styled.div`
  display: flex;
  height: 100%;
  flex: 0 0 480px;
  overflow: hidden;
  /* margin-right: 2px; */
  z-index: 3;
`

export const ListWrapper = styled.div`
  flex: 1;
  z-index: 3;
`

export const ListContainer = styled.div`
  display: flex;
  overflow: hidden;
  height: 100%;
  background-color: var(--ciBlue);
  gap: 4px;
`

export const ListItem = styled.div`
  position: relative;
  /* width: 355px; */
  width: 345px;
  /* margin-right: 2px; */
`