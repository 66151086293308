import { getDatabase, onValue, ref, set } from "firebase/database";
import { useEffect, useState } from "react"

export const useFirebaseField = (field, defaultValue) => {
  const [value, setValueInternal] = useState(defaultValue);

  const db = getDatabase();
  const dataRef = ref(db, field);
  let initialized = false;

  useEffect(() => {
    onValue(dataRef, (snapshot) => {
      let remoteValue = snapshot.val();
      if (!initialized && !remoteValue) {
        set(dataRef, defaultValue);
        return;
      }

      console.log('useFieldbaseField value change', { field, remoteValue });
      setValueInternal(remoteValue);
    });
  }, []);

  const setValue = (newValue) => {
    if (value === newValue) {
      return;
    }

    console.log('useFirebaseField setValue', { field, newValue });
    set(dataRef, newValue);
    setValueInternal(newValue);
  };

  return [value, setValue];
};
