import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { parseParams } from "../../../utils/params";

import {
  SideBarContainer,
  BannerTop,
  BannerBottom,
  Logo,
  Container,
  ListWrapper,
  ListContainer,
  ListItem,
  ListHeader,
  TextContainer,
  Border,
} from "../../templates/sidebar/SideBar.style";

import { SummaryScoreSideBar } from "../../atoms/summaryScoreSideBar/SummaryScoreSideBar";
import { ItemCardSideBarLayoutB } from '../../atoms/itemCardSideBarLayoutB/ItemCardSideBarLayoutB';
import { useIntervalOrClick } from "../../../utils/effects";

// import { getCountProgressOneDigit } from "../../../utils/overallResult";
// import { parseParams } from "../../../utils/params";
import { parties } from "../../../const/parties";
import { orderBy } from "lodash";
import { useSearchParams } from "react-router-dom";


export const SideBar5ItemsWithNamePage = () => {
  const sizeItems = 5;

  const [params] = useSearchParams();
  const lang = params.get('lang');

  const { t } = parseParams({ t: 30000, hideProgress: false });
  const [activeRegionRefCode, setActiveRegionRefCode] = useState(1);
  const [isReady, setIsReady] = useState(false);
  const [partiesData, setPartiesData] = useState([]);
  const resultsByRegionRefCode = useSelector((state) => state.regionVoteResultReducer.resultsByRegionRefCode);
  const allRegionRefCodes = useSelector((state) => state.regionReducer.allRegionRefCodes);
  const regionsByRefCode = useSelector((state) => state.regionReducer.regionsByRefCode);

  useEffect(() => {
    if (resultsByRegionRefCode && activeRegionRefCode) {
      if (resultsByRegionRefCode[activeRegionRefCode]) {
        let tempCadidates = resultsByRegionRefCode[activeRegionRefCode]?.allCandidateResults
        tempCadidates = orderBy(
          tempCadidates,
          [
            (item) => item.areaResultSeats,
            (item) => item.areaResultVotes,
          ],
          [
            'desc',
            'desc',
          ],
        );
        setPartiesData(tempCadidates.slice(0, sizeItems));
      } else {
        setPartiesData([])
      }
    }
  }, [activeRegionRefCode, resultsByRegionRefCode]);

  useEffect(() => {
    if (partiesData?.length && allRegionRefCodes?.length) {
      setIsReady(true);
    }
  }, [partiesData, allRegionRefCodes])

  useIntervalOrClick({
    isReady,
    delay: t,
    acceptClick: true,
    callback: () => {
      if (activeRegionRefCode >= (allRegionRefCodes.length + 1)) {
        setActiveRegionRefCode(1)
      } else if (activeRegionRefCode === 3) {
        // Skip RefCode = 4
        setActiveRegionRefCode((index) => index + 2);
      } else {
        setActiveRegionRefCode((index) => index + 1);
      }
    }
  });



  return (
    <div className="page-container">
      <SideBarContainer>
        <BannerTop
          style={{
            backgroundImage: `url("/static/images/l-shape-title.png")`,
          }}>
          <SummaryScoreSideBar
            titleTextFirstLine={lang === 'en' ? `Top 5 Number of seats` : `5 อันดับจำนวนที่นั่ง`}
            titleTextSecondLine={lang === 'en' ? `Constituency` : `ส.ส. เขต`}
            subText={`${activeRegionRefCode !== 7 ? 'ภาค' : ''}${regionsByRefCode[activeRegionRefCode]?.shortName || ''}`}
          />
        </BannerTop>
        <ListHeader>
          <TextContainer>{ (lang === 'en') ? 'Total (seats)' : 'รวม (ที่นั่ง)' }</TextContainer>
        </ListHeader>
        <Container>
          <ListWrapper>
            <ListContainer>
              {partiesData?.map((party, index) => {
                  return (
                    <ListItem key={`party-${party.partyRefCode}`}>
                      <motion.div
                        initial="visible"
                        // animate={{
                        //   backgroundColor: index === 0 ? "var(--ciBlue)" : "var(--white)",
                        //   color: index === 0 ? "var(--white)" : "var(--ciBlue)",
                        //   transition: {
                        //     type: "tween",
                        //     duration: 1,
                        //   },
                        // }}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <ItemCardSideBarLayoutB
                          orderNumber={index + 1}
                          imgUrl={`${parties[party.partyRefCode]?.images.logoImageUrl}`}
                          partyName={`${parties[party.partyRefCode]?.translations.th.name}`}
                          totalSeats={party.areaResultSeats}
                          totalVotes={party.areaResultVotes}
                          partyColor={parties[party.partyRefCode]?.color}
                        />
                      </motion.div>
                    </ListItem>
                  );
                })
              }
            </ListContainer>
          </ListWrapper>
        </Container>
        {/* <BannerBottom
          style={{
            backgroundImage: `url("/static/images/l-shape-footer.png")`,
          }}
        >
          <Logo>
            <img
              src="/static/images/logo.png"
              alt="logo"
              width="200" />
          </Logo>
        </BannerBottom> */}
      </SideBarContainer>
    </div>
  )
}
