export const canReportProgressPercent = (summary) => {
  if (!summary || !summary.hasCountingProgressPercent) {
    return false;
  }
  return true;
}

export const getCountProgress = (summary) => {
  if (summary?.countingProgressPercent) {
    return summary?.countingProgressPercent || 0;
  }
  return undefined;
}

export const getCountProgressOneDigit = (summary) => {
  if (summary?.countingProgressPercent) {
    return (summary?.countingProgressPercent || 0).toFixed(1);
  }
  return undefined;
}
