import { Counter } from "../counter/Counter";
// import { useSearchParams } from 'react-router-dom';
import {
  Container,
  TitleContainer,
  Title,
  Score,
  ImageWrapper,
  Triangle,
  LogoWrapper,
} from "./CandidateCardFullPage.style";

export const CandidateCardFullPage = (props) => {
  const {
    fullName,
    imgUrl,
    score,
    bgColor,
    logoUrl,
  } = props;

  return (
    <Container className="candidate-container">
      <LogoWrapper>
        <img src={logoUrl} alt={logoUrl} />
      </LogoWrapper>
      <ImageWrapper className="image-wrapper">
        <img src={imgUrl} alt={imgUrl} />
      </ImageWrapper>
      <TitleContainer className="title-container">
        <Title className="full-name">
          {fullName}
        </Title>
        <Score className="score" bgColor={bgColor}>
          <Counter from={0} to={score} />
        </Score>
      </TitleContainer>
      <Triangle bgColor={bgColor} />
    </Container> 
  )
;}