import { createSlice } from "@reduxjs/toolkit";
import { keyBy } from "lodash";

const initialState = {
  allProvinceRefCodes: [],
  allProvinceOptions: [],
  provincesByRefCode: {},
};

export const provincesSlice = createSlice({
  name: "province",
  initialState: initialState,
  reducers: {
    setProvinces: (state, action) => {
      const allProvinceRefCodes = action.payload.provinces.map(province => province.refCode);
      const allProvinceOptions = action.payload.provinces.map(province => ({
        value: province.refCode,
        label: province.translations.th.name,
      }));
      const provincesByRefCode = keyBy(action.payload.provinces, province => province.refCode)

      return {
        allProvinceRefCodes,
        allProvinceOptions,
        provincesByRefCode,
      };
    },
  },
});

export const { setProvinces } = provincesSlice.actions;

export default provincesSlice.reducer;
