import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  areaRegionIndex: 0,
};

export const lowerthirdAreaResultSlice = createSlice({
  name: "lowerthirdAreaResult",
  initialState: initialState,
  reducers: {
    setAreaRegionIndex: (state, action) => {
      state.areaRegionIndex = action.payload.areaRegionIndex;
    },
  },
});

export const { setAreaRegionIndex } = lowerthirdAreaResultSlice.actions;

export default lowerthirdAreaResultSlice.reducer;
