import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const CandidateWrapper = styled.div`
  margin: 0 auto;
  flex: 1;

  .candidate-item-container {
    flex: 1;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-size: cover;
  background-repeat: no-repeat;

  width: 1920px;
  height: 1080px;
`;

export const Wrapper = styled.div`
  background: rgba(255 255 255 / 80%);
  width: 1779px;
  height: 987px;
  position: relative;
`;

export const TitleWrapper = styled.div`
  background-color: var(--ciWhite);
  width: 100%;
  padding: 32px 70px 24px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;
  padding-right: 8px;
`;

export const Title = styled.span`
  font-size: 80px;
  font-weight: 700;
  line-height: 1;
  color: var(--ciBlue);
`;

export const Subtitle = styled.span`
  font-size: 40px;
  font-weight: 700;
  line-height: 1.4;
  color: var(--ciOrange);
`;

export const RibbonWrapper = styled.div`
  background-position: right;
  background-repeat: no-repeat;

  width: 100%;
  height: 72px;

  display: flex;
  gap: 62px;
  text-align: left;
  padding-left: 70px;
`;

export const Unit = styled.span`
  margin-left: 4px;
  font-size: 80%;
`;
