import styled from "styled-components";

export const BannerTitle = styled.div`
  font-family: 'sukhumvitset';
  font-size: 30px;
  font-weight: 700;
  line-height: 1.4;
  color: var(--ciOrange);

  position: relative;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
`

export const Logo = styled.div`
  /* position: absolute; */
  /* top: 8px; */
  /* right: 8px; */
  margin-right: 24px;
`