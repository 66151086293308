import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';

import { canReportProgressPercent, getCountProgress } from "../../../utils/overallResult";
import { Counter } from "../../atoms/counter/Counter";

import {
  Container,
  TitleContainer,
  Title,
  Wrapper,
  Subtitle,
  ContentContainer,
  TitleWrapper,
  RibbonWrapper,
  Unit,
  CountedTextContainer,
  CountedValue,
  DescriptionContainer,
} from "../../templates/overallFullPage/OverallFullPage.style"

import {
  ListItem,
} from "../../molecules/partyListFullPage2Columns/PartyListFullPage2Columns.style";

import {
  PartyWrapper,
  TableWrapper,
} from "./OverallFullPageLayoutC.style"

import { PartyListFullPage2Columns } from '../../molecules/partyListFullPage2Columns/PartyListFullPage2Columns';
import { containerVariants, countedTextMotionStyle, descriptionMotionStyle } from '../../templates/overallFullPage/OverallFullPage';
import styled from "styled-components";
import { useEffect, useState } from "react";

export const OverallFullPageLayoutC = () => {
  const fullInfoResults = useSelector((state) => state.fullInfoResultReducer.fullInfoResults);
  const summary = useSelector((state) => state.overallResultReducer.summary);
  const overlayInfo = useSelector((state) => state.miscellaneousReducer.overlay);

  const [ params ] = useSearchParams();
  const lang = params.get('lang');


  const [totalSeats, setTotalSeats] = useState(400);
  useEffect(() => {
    const newTotalSeats = fullInfoResults.reduce((result, entry) => result + entry.areaResult.seats, 0);
    setTotalSeats(newTotalSeats);
  }, [fullInfoResults])

  return (
    <div className="page-container">
        <Container
          style={{
            backgroundImage: `url('/static/images/graphic-bg.png')`,
          }}>
          <Wrapper>
            <ContentContainer>
              <TitleWrapper>
                <TitleContainer>
                  {(lang === 'en') ? (
                    <>
                      {/** เปลี่ยน Title กับ Subtitle */}
                      {/* <Title>{overlayInfo.unofficialCountingScore}</Title> */}
                      <Title>Result of MPs' vote 2023</Title>
                      <Subtitle>(Unofficial)</Subtitle>
                    </>
                  ) : (
                    <>
                      {/** Title ของหน้า e.g. ผลการนับคะแนน ส.ส. */}
                      {/* <Title>{overlayInfo.countingScore}</Title> */}
                      <Title>ผลการนับคะแนน ส.ส. 2566</Title>

                      {/** Subtext ที่วงเล็บข้างหลัง title ของหน้า e.g. (อย่างไม่เป็นทางการ) */}
                      {/* <Subtitle>{overlayInfo.unofficial}</Subtitle> */}
                      <Subtitle>(อย่างไม่เป็นทางการ)</Subtitle>
                    </>
                  )}
                </TitleContainer>
              </TitleWrapper>
              <RibbonWrapper
                style={{
                  backgroundImage: `url('/static/images/h-ribbon.png')`,
                }}
              >
                <motion.div
                  variants={containerVariants}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  style={descriptionMotionStyle}
                >
                  <DescriptionContainer>
                    { (lang === 'en') ? 'Seats of constituency MPs overall' : 'จำนวนที่นั่ง ส.ส. เขตทั่วประเทศ' }
                  </DescriptionContainer>
                </motion.div>
                <motion.div
                  variants={containerVariants}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  style={countedTextMotionStyle}
                >
                  {
                    canReportProgressPercent(summary)
                      ? <>
                        <CountedTextContainer>
                          { (lang === 'en') ? 'Counted' : 'นับแล้ว' }
                          <CountedValue>
                            <Counter from={0} to={getCountProgress(summary)} digit={1} />
                            <Unit>%</Unit>
                          </CountedValue>
                        </CountedTextContainer>
                      </>
                      : null
                  }
                </motion.div>
              </RibbonWrapper>
              <TableWrapper>
                <PartyWrapper>
                  <PartyListFullPage2Columns partyList={fullInfoResults} />
                </PartyWrapper>
                <ToTalSeats
                  key={`party-total`}
                  className="party-item-container"
                  style={{
                    backgroundImage: `url('/static/images/total-list-sm-bg.png')`,
                    width: '400px',
                    heght: '54px',
                    backgroundSize: 'contain',
                    textAlign: 'center',
                    display:'flex',
                    justifyContent: 'center',
                    margin: '0 auto',
                  }}>
                  <div>รวม</div><span className="orange">{totalSeats}</span> <div>{ (lang === 'en') ? 'seats' : 'ที่นั่ง' }</div>
                </ToTalSeats>
              </TableWrapper>
            </ContentContainer>
          </Wrapper>
        </Container>
    </div>
  )
}

const ToTalSeats = styled(ListItem)`
  font-family: 'sukhumvitset';
  font-size: 28px;
  font-weight: 700;
  line-height: 1.8;
  color: var(--grey900);

  display: grid;
  column-gap: 16px;
  .orange {
    color: var(--ciOrange);
  }
`
