import styled from "styled-components";

export const BannerTitle = styled.div`
  background-color: white;
  color: var(--ciOrange);
  font-size: 32px;
  font-weight: bold;
  position: relative;
  width: 100%;
  padding-top: 8px;
  padding-left: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const TopContainer = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 12px;
`

export const ResultTitle = styled.div`
  font-size: 32px;
  font-weight: bold;
`

export const ResultSubtitle = styled.div`
  font-size: 21px;
  font-weight: bold;
  color: var(--ciOrange);
  line-height: 1.1;
`

export const BottomContainer = styled.div`
  flex: 1;
  position: relative;
  display: flex;
`;

export const Logo = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  align-self: center;
`

export const Unit = styled.span`
  margin-left: 4px;
  font-size: 80%;
`