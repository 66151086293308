import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  /* justify-content: space-between; */
  /* align-items: flex-end; */
  overflow: hidden;

  width: 100%;
  height: 100%;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
`;

export const TitleContainer = styled.div`
  margin-top: 13px;
  /* margin-left: 21px; */
  /* margin-bottom: 10px; */

  line-height: 1.4;
  flex: 1;
  /* border: 1px solid ${(props) => props.bgColor || 'var(--grey200)'}; */
  /* max-width: 238px; */
  width: 100%;
`;

export const TitleTextWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding: 8px 16px;
  width: 100%;
`;

export const Title = styled.div`
  font-family: 'sukhumvitset';
  font-size: 24px;
  font-weight: 700;
  color: var(--ciBlue);
  
  width: 100%;
  height: 40px;
  padding-top: 2px;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
`;

export const Subtitle = styled.div`
  font-family: 'sukhumvitset';
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  color: var(--ciBlue);

  margin-bottom: 4px;
`;

export const Score = styled.div`
  font-family: 'Avenir LT Std';
  font-size: 46px;
  font-weight: 900;
  line-height: 1.3;

  width: 100%;
  min-height: 48px;
  padding-left: 16px;

  background-color: ${(props) => props.bgColor || 'var(--grey200)'};
`;

export const ImageWrapper = styled.div`
  position: absolute;
  top: 21px;
  bottom: 16px;
  right: 16px;

  /* width: 45%; */
  /* height: 100%; */
  width: 30%;
  height: 70%;
  overflow: hidden;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-color: var(--ciWhite);

  border-radius: 4px;
  border: 1px solid ${(props) => props.bgColor || 'var(--grey200)'};
`;
