import { animate, motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useState, useRef, useEffect } from "react";
import { numberWithCommas } from "../../../utils/number";

export const Counter = (props) => {
  const { digit = 0, animateOptions } = props;
  const [refContainter, isItemInView] = useInView({ threshold: 0, trackVisibility: false });
  
  return (<span ref={refContainter}> { isItemInView && <CounterItem {...props} /> }</span>);
};

/**
 * Transform number to decided decimal digits.
 * For example:
 * - 100.235 to 0 digit = 100
 * - 100.235 to 2 digits = 100.23
 *
 * @param value Value to transform.
 * @param digit Number of decimal digits.
 * @returns Transformed value.
 */

function parseNumberToFixed(value, digit) {
  const divider = Math.pow(10, Math.max(0, digit));
  return Math.round(value * divider) / divider;
}

export const CounterItem = (props) => {
  const { from, to, digit = 0, animateOptions } = props;
  const nodeRef = useRef(null);
  const oldToRef = useRef(0);

  useEffect(() => {
    setTimeout(() => {
      oldToRef.current = to == null ? 0 : to;
    }, 2000);
  }, [to]);

  useEffect(() => {
    const node = nodeRef?.current;
    // if old Value more than current value, reset from
    const calFrom = (oldToRef.current && oldToRef.current > to) ? 0 : oldToRef.current;

    // const calFrom = 0;

    if (to === undefined) {
      node.textContent = '';
      return;
    }

    const controls = animate(calFrom, to == null ? 0 : to, {
      duration: 1,
      type: "tween",
      onUpdate(value) {
        if (node) {
          node.textContent = numberWithCommas(parseNumberToFixed(value, digit), digit);
        }
      },
    });

    return () => controls.stop();
  }, [from, to, animateOptions, digit]);

  return <span ref={nodeRef} />;
};
