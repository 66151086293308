import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  
  width: 517px;
  height: 635px;

  border-top-left-radius: 100px;
  background-color: var(--white);
`

export const Triangle = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;

  width: 517px;
  height: 517px;

  background-color: ${props => props.bgColor || "var(--grey200)"};
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
`

export const LogoWrapper = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 2;

  display: flex;
  justify-content: center;

  width: 93px;
  height: 93px;

  > img {
    max-width: 100%;
    margin-top: auto;
  }
`

export const ImageWrapper = styled.div`
  z-index: 1;
  
  display: flex;
  justify-content: center;

  width: 517px;
  height: 517px;

  > img {
    max-width: 100%;
    margin-top: auto;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  z-index: 1;
  
  width: 100%;
  height: 100%;
`

export const Title = styled.div`
  font-family: 'sukhumvitset';
  font-size: 40px;
  font-weight: 700;
  line-height: 1.7;
  color: var(--ciBlue);

  display: flex;
  justify-content: center;

  width: 100%;
  height: 66px;
  padding: 0 8px;

  background-color: var(--white);
`

export const Score = styled.div`
  font-family: 'Avenir LT Std';
  font-size: 48px;
  font-weight: 900;
  line-height: 1.3;
  color: var(--white);
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 50%;
  text-align: right;
  padding: 6px 32px 0;

  width: 100%;
  height: 100%;

  background-color: ${props => props.bgColor || "var(--grey200)"};
`
