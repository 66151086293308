import { createSlice } from "@reduxjs/toolkit";
import { sortBy, keyBy } from "lodash";

const initialState = {
  districts: [],
  resultsByAreaRefCode: {},
  hoverDistrictNumber: 0
};

export const districtSlice = createSlice({
  name: "districtVoteresult",
  initialState: initialState,
  reducers: {
    setDistrictsVoteResult: (state, action) => {
      let districts = [...action.payload.districts];
      districts = sortBy(
        districts,
        [
          (district) => district.area.province.translations.th.name,
          (district) => district.areaRefCode,
        ],
      );
      state.districts = districts;
      state.resultsByAreaRefCode = keyBy(districts, district => district.areaRefCode)
    },
    setHoverDistrictNumber: (state, action) => {
      state.hoverDistrictNumber = action.payload.districtNumber;
    },
    clearHoverDistrictNumber: (state) => {
      state.hoverDistrictNumber = 0;
    }
}});

export const { setDistrictsVoteResult } = districtSlice.actions;
export const { setHoverDistrictNumber } = districtSlice.actions;
export const { clearHoverDistrictNumber } = districtSlice.actions;

export default districtSlice.reducer;
