import { Counter } from '../counter/Counter';
// import { useSearchParams } from 'react-router-dom';
import {
  Container,
  TitleContainer,
  Title,
  Score,
  ImageWrapper,
} from './PartyCardFullPage.style';

export const PartyCardFullPage = (props) => {
  const {
    imgUrl,
    partyName,
    bkkScore,
    northernScore,
    centralScore,
    isanScore,
    easternScore,
    southernScore,
    totalScore,
  } = props;

  // const [ params ] = useSearchParams();
  // const lang = params.get('lang');

  return (
    <Container className='party-container'>
      <TitleContainer className='title-container'>
        <ImageWrapper className='image-wrapper'>
          <img src={imgUrl} alt={imgUrl} />
        </ImageWrapper>
        <Title className='full-name'>{partyName}</Title>
      </TitleContainer>
      <Score className='score'>
        <Counter from={0} to={bkkScore} />
      </Score>
      <Score className='score'>
        <Counter from={0} to={northernScore} />
      </Score>
      <Score className='score'>
        <Counter from={0} to={centralScore} />
      </Score>
      <Score className='score'>
        <Counter from={0} to={isanScore} />
      </Score>
      <Score className='score'>
        <Counter from={0} to={easternScore} />
      </Score>
      <Score className='score'>
        <Counter from={0} to={southernScore} />
      </Score>
      <Score className='score total'>
        <Counter from={0} to={totalScore} />
      </Score>
    </Container>
  );
};


